import { useMatch } from 'react-router-dom';
import { Box, Theme, Typography, alpha } from '@mui/material';
import { useTranslation } from 'react-i18next';
import openBoxSearch from 'shared/assets/svg/open-box-search.svg';
import { EmptyPlaceholder } from 'shared/ui/EmptyPlaceholder/EmptyPlaceholder';
import { useSelector } from 'react-redux';
import { getAddCatalogItemMode } from 'features/AddCatalogItem/model/selectors/addCatalogItemSelectors';
import { getAddInventoryItemMode } from 'features/AddInventoryItem/model/selectors/addInventoryItemSelectors';
import { getUserCanEditPropertyCatalog } from 'entities/User/model/selectors/userPermissionsSeletors';

interface PropertyCatalogEmptyProps {
    selectedCategoryIsEmpty: boolean;
}

const emptyTextStyle = {
    color: (theme: Theme) => alpha(theme.palette.common.black, 0.5),
    typography: 'openSans.body2Medium',
    maxWidth: '280px',
};

export const PropertyCatalogEmpty = ({
    selectedCategoryIsEmpty,
}: PropertyCatalogEmptyProps) => {
    const { t } = useTranslation();

    const currentPageAddItemsInOrder = useMatch('/orders/add-items' + '/*');

    const isEditingCatalog = useSelector(getAddCatalogItemMode);
    const isEditingInventory = useSelector(getAddInventoryItemMode);
    const canEditCatalog = useSelector(getUserCanEditPropertyCatalog);
    const showEmptyTextForAddItems =
        !!isEditingInventory ||
        !!isEditingCatalog ||
        !!currentPageAddItemsInOrder;

    return (
        <Box height="100%">
            <EmptyPlaceholder image={openBoxSearch}>
                {selectedCategoryIsEmpty ? (
                    <>
                        <Typography
                            {...emptyTextStyle}
                            textAlign="center"
                            mb="24px"
                        >
                            {t('There are no Items in this Category yet')}.
                        </Typography>
                        {!showEmptyTextForAddItems && canEditCatalog && (
                            <Typography {...emptyTextStyle} textAlign="center">
                                {t(
                                    'Click on Add New Item Button to add items to the Category',
                                )}
                                .
                            </Typography>
                        )}
                    </>
                ) : (
                    <Typography {...emptyTextStyle} textAlign="center">
                        {t('Select Category to see Items')} .
                    </Typography>
                )}
            </EmptyPlaceholder>
        </Box>
    );
};
