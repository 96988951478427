import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { Supplier } from '../../types/ProductSourcingSchema';

export const fetchSuppliers = createAsyncThunk<
    { data: Supplier[] },
    void,
    ThunkConfig<string>
>('fetchSuppliers', async (_, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    try {
        const response = await extra.api.get<{ data: Supplier[] }>(
            `/v1/product_sourcing/suppliers`,
        );

        return response.data;
    } catch (e) {
        return rejectWithValue('error');
    }
});
