import { ReactNode, useContext, createContext, useMemo } from 'react';
import { useValidateNavigationBlockState } from 'features/NavigationBlockModal/lib/useValidateNavigationBlockState';

interface NavigationBlockProviderProps {
    children: ReactNode;
}

const NavigationBlockContext = createContext({
    validateNavBlock: (onSubmit: () => void, openDialog: boolean) => {},
    proceed: () => {},
});

export const NavigationBlockProvider = ({
    children,
}: NavigationBlockProviderProps) => {
    const { validateNavBlock, proceed } = useValidateNavigationBlockState();

    const valuesMemoized = useMemo(
        () => ({
            validateNavBlock,
            proceed,
        }),
        [proceed, validateNavBlock],
    );

    return (
        <NavigationBlockContext.Provider value={valuesMemoized}>
            {children}
        </NavigationBlockContext.Provider>
    );
};

export const useNavigationBlock = () => useContext(NavigationBlockContext);
