import { useOutletContext } from 'react-router-dom';
import {
    PropertyCatalogItemDataDefiner,
    PropertyCatalogItemDataDefinerProps,
} from './PropertyCatalogItemDataDefiner';
import { Box, Skeleton } from '@mui/material';

export const PropertyCatalogItemDataDefinerOutlet = () => {
    const context = useOutletContext<PropertyCatalogItemDataDefinerProps>();

    const isOrderPage = context?.moveTo === 'order';

    return context?.item ? (
        <PropertyCatalogItemDataDefiner
            isOrderPage={isOrderPage}
            {...context}
        />
    ) : (
        <Box
            p="16px"
            sx={{
                height: '100%',
                width: '100%',
            }}
        >
            <Skeleton
                component={Box}
                sx={{
                    height: '100%',
                    width: '100%',
                    transform: 'scale(1)',
                }}
            />
        </Box>
    );
};
