/* eslint-disable no-constant-condition */
import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    TableContainer,
    Skeleton,
    Box,
} from '@mui/material';
import { SearchField } from 'shared/ui/SearchField/SearchField';
import { getVendorsList } from 'entities/Vendors';
import { getIsLoadingVendorsList } from 'entities/Vendors/model/selectors/vendorsSelectors';
import { getRouteVendorsSupplierPage } from 'shared/const/router';
import { Image } from 'widgets/Image';
import placeholder from 'shared/assets/svg/supplier.svg';
import { getSelectedPropertyId } from 'entities/Property/model/selectors/PropertySelectors';

export const SuppliersTabPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const vendorsList = useSelector(getVendorsList);
    const isLoadingVendors = useSelector(getIsLoadingVendorsList);
    const selectedPropertyId = useSelector(getSelectedPropertyId);
    const [search, setSearch] = useState('');

    const goToSupplierPage = useCallback(
        (supplierId: string) => {
            navigate(
                getRouteVendorsSupplierPage(supplierId, selectedPropertyId),
            );
        },
        [navigate, selectedPropertyId],
    );

    return (
        <>
            <SearchField
                value={search}
                setValue={setSearch}
                placeholder={t('Search')}
            />
            <TableContainer>
                <Table
                    sx={{
                        marginTop: '24px',
                        '& td': {
                            padding: '6px 16px',
                            borderBottom: 'none',
                            height: '50px',
                        },
                        '& tr': {
                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                        },
                        '& th': {
                            paddingTop: '0px',
                            paddingBottom: '10px',
                            color: 'rgba(0,0,0,0.5)',
                        },
                    }}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                <Typography typography="openSans.captionMedium">
                                    {t('Name')}
                                </Typography>
                            </TableCell>
                            <TableCell align="left">
                                <Typography typography="openSans.captionMedium">
                                    {t('Contact')}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!isLoadingVendors &&
                            vendorsList?.map((row) => (
                                <TableRow
                                    key={row.id}
                                    onClick={() => goToSupplierPage(row.id)}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <TableCell>
                                        <Box
                                            display="flex"
                                            gap={1}
                                            alignItems="center"
                                        >
                                            <Image
                                                src={row?.logo}
                                                style={{
                                                    aspectRatio: '32/32',
                                                    maxWidth: '32px',
                                                    height: 'auto',
                                                    width: '100%',
                                                }}
                                                placeholder={placeholder}
                                            />
                                            <Typography typography="openSans.body2Medium">
                                                {row.title}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        {row?.phone && (
                                            <Typography typography="openSans.body2">
                                                {row.phone}
                                            </Typography>
                                        )}
                                        <Typography
                                            color="#5492F4"
                                            typography={
                                                row?.phone
                                                    ? 'openSans.caption'
                                                    : 'openSans.body2'
                                            }
                                        >
                                            {row.email}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {isLoadingVendors &&
                Array.from({ length: 10 }).map((_, index) => (
                    <Skeleton
                        key={index}
                        variant="rectangular"
                        height="50px"
                        sx={{
                            marginBottom: '2px',
                            width: '100%',
                        }}
                    />
                ))}
        </>
    );
};
