import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { Category } from '../../types/CategorySchema';

export const fetchCategories = createAsyncThunk<
    Category[],
    string,
    ThunkConfig<string>
>('category/fetchCategories', async (catalogId, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    try {
        const response = await extra.api.get<Category[]>(
            `/v1/catalogs/${catalogId}`,
        );

        return response.data;
    } catch (e) {
        return rejectWithValue('error');
    }
});
