import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getRouteOrders, getRouteOrdersView } from 'shared/const/router';

export const useNavaigateFromOrder = () => {
    const navigate = useNavigate();

    const location = useLocation();

    const isNavigatedFromSingleOrder = location.state?.fromOrder;

    const onNavigateBack = useCallback(() => {
        const orderId = location.state?.fromOrder;
        if (orderId) {
            navigate(getRouteOrdersView(orderId));
        } else {
            navigate(getRouteOrders());
        }
    }, [location.state?.fromOrder, navigate]);

    return { isNavigatedFromSingleOrder, onNavigateBack };
};
