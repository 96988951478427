import { ReactNode, memo, useEffect } from 'react';
import { Box } from '@mui/material';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { moveToInventoryItemActions } from 'features/moveToInventoryCatalogItem/model/slices/MoveToInventoryItemSlice';
import { useInitialEffect } from 'shared/lib/hooks/useInitialEffect';

interface WithMoveToInventoryCatalogItemProps {
    children: ReactNode;
    isSingle?: boolean;
    isDisabled?: boolean;
}

export const WithMoveToInventoryCatalogItem = memo(
    ({
        children,
        isSingle,
        isDisabled,
    }: WithMoveToInventoryCatalogItemProps) => {
        const dispatch = useAppDispatch();

        useInitialEffect(() => {
            dispatch(moveToInventoryItemActions.setIsSingle(isSingle));
        });

        useEffect(() => {
            dispatch(moveToInventoryItemActions.setIsSingle(isSingle));
        }, [dispatch, isSingle]);

        const onShowMoveToInventoryDialog = () => {
            if (!isDisabled) {
                dispatch(moveToInventoryItemActions.setShowDialog(true));
            }
        };

        return (
            <Box
                display="flex"
                sx={{ cursor: 'pointer' }}
                onClick={onShowMoveToInventoryDialog}
            >
                {children}
            </Box>
        );
    },
);
