import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { Order } from 'entities/Order/model/types/OrderSchema';
import { EditPurchaseOrderNumberRequestParams } from '../types/OrderProposalFilterSchema';

export const editPurchaseOrderNumber = createAsyncThunk<
    Order,
    EditPurchaseOrderNumberRequestParams,
    ThunkConfig<string>
>(
    'order/editPurchaseOrderNumber',
    async ({ orderId, supplierId, poNumber }, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi;

        try {
            const response = await extra.api.post<Order>(
                `/v1/orders/${orderId}/po-number/${supplierId}`,
                {
                    poNumber,
                },
            );

            return response.data;
        } catch (e) {
            return rejectWithValue('error');
        }
    },
);
