export enum NotificationChangeField {
    STATUS = 'status',
    TRIGGEREDBY = 'triggered_by',
}

export enum NotificationChangeContent {
    ORDER = 'order',
}

export const notificationPageSize = 40;
