import { CatalogItemInOrder } from '../model/types/CatalogItemSchema';

export const mapCatalogItemFromOrder = (item: CatalogItemInOrder) => {
    return {
        selection: {
            qty: item.qty,
            orderItemId: item.id,
        },
        info: {
            ...item.itemInfoFrozen,
            id: item.id,
            proposals: item?.proposals,
            selectedProposal: item.selectedProposal,
        },
    };
};
