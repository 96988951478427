import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { USER_ACCESS_TOKEN } from 'shared/const/localstorage';

const apiUrl =
    process.env.REACT_APP_REOPS_API ||
    `${window.location.protocol}//api.${window.location.hostname}`;

export const rtkApi = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: apiUrl,
        prepareHeaders: (headers) => {
            const token = `Bearer ${localStorage.getItem(USER_ACCESS_TOKEN)}`;
            if (token) {
                headers.set('Authorization', token);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({}),
});
