import { Box, Typography } from '@mui/material';
import { getSelectedCatalogItemsTotal } from 'entities/CatalogItem/model/selectors/catalogItemSelectors';
import { getUserCanSeePrices } from 'entities/User/model/selectors/userPermissionsSeletors';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

interface CatalogItemsSelectedCounterProps {
    total?: string;
    title?: string;
}

export const CatalogItemsSelectedCounter = ({
    total,
    title,
}: CatalogItemsSelectedCounterProps) => {
    const { t } = useTranslation();
    const totalItemsCount = useSelector(getSelectedCatalogItemsTotal);
    const canSeePrices = useSelector(getUserCanSeePrices);

    const textForSelectedItems =
        totalItemsCount === 1 ? t('Item Selected') : t('Items Selected');

    const titleToDisplay = title || textForSelectedItems;

    return (
        <Box
            height="24px"
            mb="4px"
            display="flex"
            gap="4px"
            justifyContent="flex-end"
        >
            {totalItemsCount > 0 && (
                <>
                    <Typography textAlign="end" color="primary">
                        {titleToDisplay}: {totalItemsCount}
                    </Typography>
                    {canSeePrices && total ? (
                        <Typography textAlign="end" color="primary">
                            (${total})
                        </Typography>
                    ) : null}
                </>
            )}
        </Box>
    );
};
