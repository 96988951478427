import { memo } from 'react';
import { Box, BoxProps } from '@mui/material';
import { PropertyCardRow } from './PropertyCardRow';
import { useTranslation } from 'react-i18next';
import { Property } from 'entities/Property/model/types/PropertySchema';

type PropertyCardMainInfoProps = {
    propertyItem: Property;
} & BoxProps;

export const PropertyCardMainInfo = memo(
    ({ mb, propertyItem }: PropertyCardMainInfoProps) => {
        const { t } = useTranslation();

        return (
            <Box mb={mb}>
                <PropertyCardRow
                    bordered
                    name={t('Owner Entity')}
                    value={propertyItem?.ownerEntity || '-'}
                />
                <PropertyCardRow
                    bordered
                    name={t('Address Line 1')}
                    value={propertyItem?.address1 || '-'}
                />
                <PropertyCardRow
                    bordered
                    name={t('Address Line 2')}
                    value={propertyItem?.address2 || '-'}
                />
                <PropertyCardRow
                    bordered
                    name={t('City')}
                    value={propertyItem?.city || '-'}
                />
                <PropertyCardRow
                    bordered
                    name={t('State')}
                    value={propertyItem?.state || '-'}
                />
                <PropertyCardRow
                    bordered
                    name={t('Zip')}
                    value={propertyItem?.zipCode || '-'}
                />
            </Box>
        );
    },
);
