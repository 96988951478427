import { Box } from '@mui/material';
import { ReactNode } from 'react';

interface TruncatedTextProps {
    width?: string;
    children: ReactNode;
}

export const TruncatedText = ({ width, children }: TruncatedTextProps) => {
    return (
        <Box
            component="p"
            maxWidth={width || undefined}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            m="0"
        >
            {children}
        </Box>
    );
};
