import {
    getRoutePrivacyPolicy,
    getRouteTermsOfService,
} from 'shared/const/router';

export function usePublicPageDetector() {
    const publicPages = [getRoutePrivacyPolicy(), getRouteTermsOfService()];

    return publicPages.includes(window.location.pathname);
}
