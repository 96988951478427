import { useTranslation } from 'react-i18next';
import { memo, useState } from 'react';
import { Box } from '@mui/material';
import { SearchField } from 'shared/ui/SearchField/SearchField';
import { useDebouncedCallback } from 'shared/lib/hooks/useDebouncedCallback';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import { getSearchCatalogItemIsActive } from 'features/SearchCatalogItems/model/selectors/searchCatalogItemsSelectors';
import { searchCatalogItemsActions } from 'features/SearchCatalogItems/model/slices/SearchCatalogItemsSlice';
import { fetchCatalogItems } from 'entities/CatalogItem/model/services/fetchCatalogItems/fetchCatalogItems';
import { catalogItemActions } from 'entities/CatalogItem/model/slices/CatalogItemSlice';
import { getCatalogItemsAreLoading } from 'entities/CatalogItem/model/selectors/catalogItemSelectors';
import { CatalogItemsRequestData } from 'entities/CatalogItem/model/types/CatalogItemSchema';
import { PropertyCatalogItemsMoveTo } from 'widgets/PropertyCatalog';
import { useFetchWIthController } from 'shared/lib/hooks/useFetchWIthController';
import { fetchGlobalCatalogItems } from 'entities/CatalogItem';

interface SearchCatalogItemsProps {
    catalogId: string;
    hideBorder: boolean;
    moveTo: PropertyCatalogItemsMoveTo;
}

export const SearchCatalogItemsField = memo(
    ({ catalogId, hideBorder, moveTo }: SearchCatalogItemsProps) => {
        const { t } = useTranslation();
        const dispatch = useAppDispatch();
        const fetchWithController = useFetchWIthController();

        const moveToOrder = moveTo === PropertyCatalogItemsMoveTo.ORDER;
        const moveToInventory = moveTo === PropertyCatalogItemsMoveTo.INVENTORY;

        const isActive = useSelector(getSearchCatalogItemIsActive);
        const isLoading = useSelector(getCatalogItemsAreLoading);

        const [search, setSearch] = useState<string>('');
        const searchPlaceholder = `${t('Search by Item Name')}, ${t(
            'Model or SKU',
        )}...`;

        const searchItemsDebounced = useDebouncedCallback(
            async (params: CatalogItemsRequestData) => {
                await dispatch(fetchCatalogItems(params));
            },
        );

        const onSetValue = async (searchStr: string) => {
            if (!searchStr && isActive) {
                dispatch(searchCatalogItemsActions.setActive(false));
            } else if (searchStr && !isActive) {
                dispatch(searchCatalogItemsActions.setActive(true));
            }

            if (!isLoading && searchStr) {
                dispatch(catalogItemActions.setLoading(true));
            }

            setSearch(searchStr);

            if (searchStr) {
                const data = {
                    catalogId,
                    searchStr,
                };

                if (moveToOrder || moveToInventory) {
                    fetchWithController((controller: AbortController) =>
                        searchItemsDebounced({
                            data,
                            abortController: controller,
                        }),
                    );
                } else {
                    fetchWithController((controller: AbortController) =>
                        dispatch(
                            fetchGlobalCatalogItems({
                                searchString: searchStr,
                                abortController: controller,
                            }),
                        ),
                    );
                }
            }
        };

        return (
            <Box>
                <SearchField
                    hideBorder={hideBorder}
                    placeholder={searchPlaceholder}
                    value={search}
                    setValue={onSetValue}
                />
            </Box>
        );
    },
);
