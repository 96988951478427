import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import {
    CatalogItem,
    CatalogItemsRequestData,
    GlobalCatalogItem,
} from '../../types/CatalogItemSchema';

export const fetchCatalogItems = createAsyncThunk<
    CatalogItem[] | GlobalCatalogItem[],
    CatalogItemsRequestData,
    ThunkConfig<string>
>(
    'catalogItem/fetchCatalogItems',
    async ({ data, abortController }, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi;

        const { catalogId, categoryId, searchStr } = data;

        try {
            const response = await extra.api.get<
                CatalogItem[] | GlobalCatalogItem[]
            >(`/v1/catalogs/${catalogId}`, {
                params: {
                    categoryId,
                    searchStr,
                    is_loading_proposals: false,
                },
                signal: abortController?.signal,
            });

            return response.data;
        } catch (e) {
            return rejectWithValue('error');
        }
    },
);
