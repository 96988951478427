import { useEffect } from 'react';
import { isJestRuntime } from 'shared/const/envVariables';

export function useUnmountEffect(
    callback: () => void | Promise<void>,
    blockForTests?: boolean,
) {
    useEffect(
        () => () => {
            if (blockForTests && isJestRuntime) {
                return;
            }
            callback();
        },
        // eslint-disable-next-line
        [],
    );
}
