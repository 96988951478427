import { useTranslation } from 'react-i18next';
import { ChangeEvent, memo, useEffect } from 'react';
import { TextField } from '@mui/material';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import {
    getEditMinAllowedInStockValue,
    getEditMinAllowedInStockValueIsChanged,
} from 'features/EditMinAllowedInStock';
import { editMinAllowedInStockActions } from 'features/EditMinAllowedInStock/model/slices/EditMinAllowedInStockSlice';
import { useInitialEffect } from 'shared/lib/hooks/useInitialEffect';

const minValue = 0;
const maxValue = 99999;

interface EditMinAllowedInStockInputProps {
    initialValue: number;
    readOnly: boolean;
}

export const EditMinAllowedInStockInput = memo(
    ({ initialValue, readOnly }: EditMinAllowedInStockInputProps) => {
        const { t } = useTranslation();
        const dispatch = useAppDispatch();

        useInitialEffect(() => {
            dispatch(editMinAllowedInStockActions.setValue(initialValue));
        });

        const value = useSelector(getEditMinAllowedInStockValue);
        const valueChanged = useSelector(
            getEditMinAllowedInStockValueIsChanged,
        );

        useEffect(() => {
            if (initialValue !== value && !valueChanged) {
                dispatch(editMinAllowedInStockActions.setValueIsChanged(true));
            } else if (initialValue === value && valueChanged) {
                dispatch(editMinAllowedInStockActions.setValueIsChanged(false));
            }
        }, [dispatch, initialValue, value, valueChanged]);

        const onChange = (event: ChangeEvent<HTMLInputElement>) => {
            let currentValue = event.target.value;

            const regex = /^[0-9\b]+$/;
            if (currentValue === '' || regex.test(currentValue)) {
                const newValue = Math.min(
                    Math.max(Number(currentValue), minValue),
                    maxValue,
                );
                dispatch(editMinAllowedInStockActions.setValue(newValue));
            }
        };

        return (
            <TextField
                data-testid="Min-on-hand-input"
                fullWidth
                label={t('Min on Hand')}
                value={`${Number(value)}`}
                disabled={readOnly}
                onChange={onChange}
            />
        );
    },
);
