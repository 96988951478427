import { getSelectedCatalogItems } from 'entities/CatalogItem/model/selectors/catalogItemSelectors';
import {
    CatalogItem,
    GlobalCatalogItem,
} from 'entities/CatalogItem/model/types/CatalogItemSchema';
import { useSelector } from 'react-redux';
import { PropertyCatalogItem } from './PropertyCatalogItem';
import { has } from 'shared/lib/lodash/lodash';
import { PropertyCatalogItemsMoveTo } from 'widgets/PropertyCatalog/models/consts/propertyCatalogItemsListTypes';
import { useCallback, useMemo } from 'react';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { catalogItemActions } from 'entities/CatalogItem/model/slices/CatalogItemSlice';
import { PropertyCatalogItemDetailed } from 'shared/ui/PropertyCatalogItemDetailed/PropertyCatalogItemDetailed';
import { getVendorsList } from 'entities/Vendors';

export interface PropertyCatalogItemDataDefinerProps {
    item: CatalogItem | GlobalCatalogItem;
    quickEdit?: boolean;
    moveTo?: PropertyCatalogItemsMoveTo;
    isDetailed?: boolean;
    isOrderPage?: boolean;
    isSearch?: boolean;
}

export const PropertyCatalogItemDataDefiner = ({
    item,
    quickEdit,
    moveTo,
    isSearch,
    isDetailed,
    isOrderPage,
}: PropertyCatalogItemDataDefinerProps) => {
    const dispatch = useAppDispatch();
    const selectedItems = useSelector(getSelectedCatalogItems);
    const vendorsList = useSelector(getVendorsList);

    const isLocalCatalogItem = 'id' in item;

    const id = isLocalCatalogItem ? item.id : item.uid;
    const uid = isLocalCatalogItem ? item.uuid : undefined;
    const itemIsInOrder = Boolean(
        selectedItems &&
            id in selectedItems &&
            selectedItems?.[id]?.orderItemId,
    );

    const idToEdit = itemIsInOrder ? selectedItems?.[id].orderItemId : id;

    const title = isLocalCatalogItem ? item.title : item.name;
    const price = isLocalCatalogItem ? item.price : undefined;
    const isInventory = isLocalCatalogItem ? item.isInventory : false;

    const categoryId = isLocalCatalogItem ? item.categoryId : undefined;
    const minAllowedInStock = isLocalCatalogItem
        ? item.minAllowedInStock
        : undefined;

    const isActive = has(selectedItems, id);
    const qty = selectedItems?.[id]?.qty;
    const { sku, uom, qtyPerUom, imageUrl, thumbnailUrl, uomPrice, proposals } =
        item;

    const onCancelDetailedView = useCallback(() => {
        dispatch(catalogItemActions.setShowDetailedView(false));
    }, [dispatch]);

    const activatedAccounts = useMemo(() => {
        const result: { [key: string]: boolean[] } = {};

        if (proposals && vendorsList) {
            vendorsList.forEach((vendor) => {
                const uuid = vendor.externalUuid;
                const foundInProposals = proposals.find(
                    (proposal) => proposal.seller.uid === uuid,
                );

                if (foundInProposals) {
                    const isOperationsActivated =
                        vendor.isOperationsActivated || false;
                    const isRenovationsActivated =
                        vendor.isRenovationsActivated || false;
                    result[uuid] = [
                        isOperationsActivated,
                        isRenovationsActivated,
                    ];
                } else {
                    result[uuid] = [false, false];
                }
            });
        }
        return result;
    }, [proposals, vendorsList]);

    return isDetailed ? (
        <PropertyCatalogItemDetailed
            onCancel={onCancelDetailedView}
            price={price}
            uomPrice={uomPrice}
            moveTo={moveTo}
            id={id}
            isOrderPage={isOrderPage}
            minAllowedInStock={minAllowedInStock}
            title={title}
            imageUrl={imageUrl}
            sku={sku}
            uom={uom}
            qtyPerUom={qtyPerUom}
            isLocal={isLocalCatalogItem}
            proposals={proposals}
            activatedAccounts={activatedAccounts}
        />
    ) : (
        <PropertyCatalogItem
            key={id}
            uid={uid}
            isActive={isActive}
            isInOrder={itemIsInOrder}
            isOrderPage={isOrderPage}
            quickEdit={quickEdit}
            moveTo={moveTo}
            id={id}
            categoryId={categoryId}
            idToEdit={idToEdit}
            title={title}
            thumbnailUrl={thumbnailUrl}
            isSearch={isSearch}
            qty={qty}
            sku={sku}
            price={price}
            uom={uom}
            qtyPerUom={qtyPerUom}
            minAllowedInStock={minAllowedInStock}
            isInventory={isInventory}
            proposals={proposals}
            activatedAccounts={activatedAccounts}
        />
    );
};
