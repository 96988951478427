import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { SupplierGeneralInfoSchema } from '../types/SupplierSchema';

export const fetchSupplierGeneralInfo = createAsyncThunk<
    SupplierGeneralInfoSchema,
    string,
    ThunkConfig<string>
>('supplier/general-info', async (supplierId, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    try {
        const response = await extra.api.get<SupplierGeneralInfoSchema>(
            `/v1/vendors/${supplierId}`,
        );

        return response.data;
    } catch (e) {
        return rejectWithValue('error');
    }
});
