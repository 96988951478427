import React from 'react';
import { Box } from '@mui/material';

interface ImageProps {
    src?: string | null;
    placeholder?: React.FC<React.SVGProps<SVGSVGElement>>;
    style?: object;
}

export const Image = ({ src, placeholder, style }: ImageProps) => {
    const imageCss = {
        height: '100%',
        width: '100%',
        display: 'block',
    };
    const containerCss = {
        ...style,
        backgroundColor: 'white',
        backgroundImage: `url(${placeholder})`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
    };

    return (
        <Box style={containerCss}>
            {src && (
                <img
                    data-testid="image"
                    src={src}
                    style={imageCss}
                    onLoad={(event) => {
                        let parent = (event.target as HTMLElement)
                            .parentNode as HTMLElement;
                        parent.style.backgroundImage = 'none';
                    }}
                    // @ts-ignore
                    onError={(event) => (event.target.style.display = 'none')}
                />
            )}
        </Box>
    );
};
