import { configureStore, ReducersMapObject } from '@reduxjs/toolkit';
import { CombinedState, Reducer } from 'redux';
import { userReducer } from 'entities/User';
import { StateSchema, ThunkExtraArg } from './StateSchema';
import { createReducerManager } from './reducerManager';
import { $api } from 'shared/api/api';
import { rtkApi } from 'shared/api/rtkApi';
import { profileReducer } from 'features/ProfileCard/model/slices/ProfileCardSlice';
import { propertyReducer } from 'entities/Property/model/slices/PropertySlice';
import { categoryReducer } from 'entities/Category/model/slices/CategorySlice';
import { catalogItemReducer } from 'entities/CatalogItem/model/slices/CatalogItemSlice';
import { fetchPropertyByIdReducer } from 'features/fetchPropertyById/model/slices/FetchPropertyByIdSlice';
import { addCatalogItemReducer } from 'features/AddCatalogItem/model/slices/AddCatalogItemSlice';
import { orderReducer } from 'entities/Order/model/slices/OrderSlice';
import { addItemsToOrderReducer } from 'features/AddItemsToOrder/model/slices/addItemsToOrderSlice';
import { removeCatalogItemReducer } from 'features/removeCatalogItem/model/slices/RemoveCatalogItemSlice';
import { searchCatalogItemsReducer } from 'features/SearchCatalogItems/model/slices/SearchCatalogItemsSlice';
import { navigationBlockModalReducer } from 'features/NavigationBlockModal/model/slices/NavigationBlockModalSlice';
import { inventoryTableReducer } from 'widgets/InventoryTable/model/slices/InventoryTableSlice';
import { addInventoryItemReducer } from 'features/AddInventoryItem/model/slices/AddInventoryItemSlice';
import { moveToInventoryItemReducer } from 'features/moveToInventoryCatalogItem/model/slices/MoveToInventoryItemSlice';
import { removeInventoryItemReducer } from 'features/RemoveInventoryItem/model/slices/RemoveInventoryItemSlice';
import { reorderInventoryItemReducer } from 'features/ReorderInventoryItem/model/slices/ReorderInventoryItemSlice';
import { editMinAllowedInStockReducer } from 'features/EditMinAllowedInStock/model/slices/EditMinAllowedInStockSlice';
import { NotificationsReducer } from 'features/Notifications/model/slices/NotificationsSlice';
import { NotificationReducer } from 'entities/Notification/model/slices/NotificationsSlice';
import { orderTableReducer } from 'widgets/OrdersTable/model/slices/OrderTableSlice';
import { orderProposalFilterReducer } from 'features/OrderProposalFilter/model/slices/OrderProposalFilterSlice';
import { getLabelsReducer } from 'features/GetLabelsPrint/model/slices/getLabelsSlice';
import { updateOrderItemProposalReducer } from 'features/UpdateOrderItemProposal/model/slices/UpdateOrderItemProposalSlice';
import { reportsReducer } from 'entities/Reports/model/slices/ReportsSlice';
import { productSourcingReducer } from 'entities/ProductSourcing/model/slices/ProductSourcingSlice';
import { vendorsReducer } from 'entities/Vendors/model/slices/VendorsSlice';
import { supplierReducer } from 'entities/Supplier/model/slices/SupplierSlice';

export function createReduxStore(
    initialState?: StateSchema,
    asyncReducers?: ReducersMapObject<StateSchema>,
) {
    const rootReducers: ReducersMapObject<StateSchema> = {
        ...asyncReducers,
        user: userReducer,
        profile: profileReducer,

        property: propertyReducer,
        propertyItem: fetchPropertyByIdReducer,

        category: categoryReducer,

        getLabelsForDownload: getLabelsReducer,
        catalogItem: catalogItemReducer,
        addCatalogItem: addCatalogItemReducer,
        removeCatalogItem: removeCatalogItemReducer,
        searchCatalogItems: searchCatalogItemsReducer,
        editMinAllowedInStock: editMinAllowedInStockReducer,

        addInventoryItem: addInventoryItemReducer,
        removeInventoryItem: removeInventoryItemReducer,
        moveToInventoryItem: moveToInventoryItemReducer,
        reorderInventoryItem: reorderInventoryItemReducer,

        notifications: NotificationsReducer,
        notification: NotificationReducer,

        order: orderReducer,
        orderTable: orderTableReducer,
        orderProposalFilter: orderProposalFilterReducer,

        updateOrderItemProposal: updateOrderItemProposalReducer,

        inventoryTable: inventoryTableReducer,
        addItemsToOrder: addItemsToOrderReducer,
        navigationBlock: navigationBlockModalReducer,
        reports: reportsReducer,
        productSourcing: productSourcingReducer,
        vendors: vendorsReducer,
        supplier: supplierReducer,
    };

    const reducerManager = createReducerManager(rootReducers);

    const extraArg: ThunkExtraArg = {
        api: $api,
    };

    const store = configureStore({
        reducer: reducerManager.reduce as Reducer<CombinedState<StateSchema>>,
        preloadedState: initialState,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                thunk: {
                    extraArgument: extraArg,
                },
            }).concat(rtkApi.middleware),
    });

    // @ts-ignore
    store.reducerManager = reducerManager;

    return store;
}

export type AppDispatch = ReturnType<typeof createReduxStore>['dispatch'];
