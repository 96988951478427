import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RemoveInventoryItemSchema } from '../types/RemoveInventoryItemSchema';
import { toast } from 'react-toastify';
import { removeInventoryItemService } from '../services/removeInventoryItemService';

const initialState: RemoveInventoryItemSchema = {
    showDialog: false,
};

export const RemoveInventoryItemSlice = createSlice({
    name: 'RemoveInventoryItem',
    initialState,
    reducers: {
        setShowDialog: (state, action: PayloadAction<boolean>) => {
            state.showDialog = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(removeInventoryItemService.pending, (state) => {
                state.error = undefined;
                state.isLoading = true;
            })
            .addCase(removeInventoryItemService.fulfilled, (state) => {
                state.isLoading = false;
                toast('Items removed from Inventory');
            })
            .addCase(removeInventoryItemService.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

export const { actions: removeInventoryItemActions } = RemoveInventoryItemSlice;
export const { reducer: removeInventoryItemReducer } = RemoveInventoryItemSlice;
