import { Box, Typography } from '@mui/material';
import { Proposal } from 'entities/Proposal/model/types/ProposalSchema';
import { ColoredLinkBox } from 'shared/ui/ColoredLinkBox';

interface SuppliersListProps {
    proposals: Proposal[];
    activatedAccounts: { [key: string]: boolean[] };
}

export const SuppliersList = ({
    proposals,
    activatedAccounts,
}: SuppliersListProps) => (
    <>
        {proposals?.map((proposal) => {
            const statuses = activatedAccounts[proposal.seller.uid] || [
                false,
                false,
            ];

            const [isOperationsActivated, isRenovationsActivated] = statuses;

            return (
                <Box
                    key={proposal.uid}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '8px',
                    }}
                >
                    <Typography
                        sx={{
                            marginY: '10px',
                            color: '#1D617A',
                        }}
                        typography="openSans.subtitle1Medium"
                    >
                        {proposal.seller.name}
                    </Typography>

                    {isOperationsActivated && (
                        <ColoredLinkBox
                            color="#DBEFE1"
                            tooltipText="Operations account activated"
                        />
                    )}

                    {isRenovationsActivated && (
                        <ColoredLinkBox
                            color="#5CA1FF66"
                            tooltipText="Renovations account activated"
                        />
                    )}
                </Box>
            );
        })}
    </>
);
