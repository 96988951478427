import { getSelectedCatalogItems } from 'entities/CatalogItem/model/selectors/catalogItemSelectors';
import { CatalogItem } from 'entities/CatalogItem/model/types/CatalogItemSchema';
import { useSelector } from 'react-redux';
import { has } from 'shared/lib/lodash/lodash';

interface UseGetItemDataUpdateProps {
    item: CatalogItem;
}

export const useGetItemDataUpdate = ({ item }: UseGetItemDataUpdateProps) => {
    const selectedItems = useSelector(getSelectedCatalogItems);

    const qty = selectedItems?.[item?.id]?.qty;
    const isActive = has(selectedItems, item?.id);

    const itemIsInOrder = Boolean(
        selectedItems &&
            item?.id in selectedItems &&
            selectedItems?.[item?.id]?.orderItemId,
    );

    const idToEdit = itemIsInOrder
        ? selectedItems?.[item?.id].orderItemId
        : item?.id;

    return { idToEdit, itemIsInOrder, qty, isActive };
};
