import { StateSchema } from 'app/providers/StoreProvider';

export const getAddCatalogItemMode = (state: StateSchema) =>
    state.addCatalogItem.addItemMode;

export const getShowDialog = (state: StateSchema) =>
    state.addCatalogItem.showDialog;

export const getAddCatalogItemSelectedId = (state: StateSchema) =>
    state.addCatalogItem.selectedId;

export const getAddCatalogItemIsLoading = (state: StateSchema) =>
    state.addCatalogItem.isLoading;

export const getAddCatalogItemDuplicates = (state: StateSchema) =>
    state.addCatalogItem.duplicates;

export const getAddCatalogAddedItemsCount = (state: StateSchema) =>
    state.addCatalogItem.addedItemsCount;
