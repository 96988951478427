import { Category } from '../model/types/CategorySchema';

export const updateAndGetCategoryList = (
    currentCategoriesList: Category[],
    newItem: Category,
) => {
    const updatedLocalCategories = [...(currentCategoriesList || [])];

    let newCategoriesList = updatedLocalCategories.filter(
        (category) => category?.id !== newItem?.id,
    );

    let index = newCategoriesList.findIndex((item: Category) => {
        return (
            item?.title.toLocaleLowerCase() > newItem?.title.toLocaleLowerCase()
        );
    });

    if (index === -1) {
        index = updatedLocalCategories?.length || 0;
    }

    newCategoriesList.splice(index, 0, newItem);
    return newCategoriesList;
};
