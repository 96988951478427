import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserProfile, UserSchema } from '../types/user';
import { USER_ACCESS_TOKEN } from 'shared/const/localstorage';
import { fetchUser } from '../services/fetchUser/fetchUser';
import { updateUser } from '../services/updateUser/updateUser';
import { AuthService } from 'shared/services/authService';

const initialState: UserSchema = {
    _inited: false,
    isAuthed: false,
    isProfileLoading: false,
    isAuthLoading: false,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateAuthData: (state, action: PayloadAction<UserProfile>) => {
            state.userData = action.payload;
        },
        initAuthData: (state) => {
            state._inited = true;
        },
        authenticateUser: (state) => {
            state.isAuthed = true;
        },
        login: (_, action: PayloadAction<string>) => {
            localStorage.setItem(USER_ACCESS_TOKEN, action.payload);
        },
        logout: (state) => {
            const authService = new AuthService();
            localStorage.removeItem(USER_ACCESS_TOKEN);
            state.userData = undefined;
            state.isAuthed = false;
            authService.logout();
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUser.pending, (state) => {
                state.error = undefined;
                state.isProfileLoading = true;
                state.isAuthLoading = true;
            })
            .addCase(
                fetchUser.fulfilled,
                (state, action: PayloadAction<UserProfile>) => {
                    state.userData = action.payload;
                    state.isProfileLoading = false;
                    state.isAuthLoading = false;
                },
            )
            .addCase(fetchUser.rejected, (state, action) => {
                state.error = action.payload;
                state.isProfileLoading = false;
                state.isAuthLoading = false;
            })
            .addCase(updateUser.pending, (state) => {
                state.isProfileLoading = true;
                state.error = undefined;
            })
            .addCase(
                updateUser.fulfilled,
                (state, action: PayloadAction<UserProfile>) => {
                    state.isProfileLoading = false;
                    state.userData = action.payload;
                },
            )
            .addCase(updateUser.rejected, (state, action) => {
                state.isProfileLoading = false;
                state.error = action.payload;
            });
    },
});

export const { actions: userActions } = userSlice;
export const { reducer: userReducer } = userSlice;
