import { createSlice } from '@reduxjs/toolkit';
import { SupplierSchema } from '../types/SupplierSchema';
import { fetchSupplierGeneralInfo } from '../services/fetchSupplierGeneralInfo';
import { fetchSupplierAccountManagerInfo } from '../services/fetchSupplierAccountManagerInfo';
import { updateSupplierAccountManagerInfo } from '../services/updateSupplierAccountManagerInfo';

const initialState: SupplierSchema = {
    dataIsLoading: false,
    supplierGeneralInfo: null,
    supplierAccountManagerInfo: null,
};

export const SupplierSlice = createSlice({
    name: 'Supplier',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSupplierGeneralInfo.pending, (state) => {
                state.dataIsLoading = true;
            })
            .addCase(fetchSupplierGeneralInfo.fulfilled, (state, action) => {
                state.supplierGeneralInfo = action.payload;
                state.dataIsLoading = false;
            })
            .addCase(fetchSupplierGeneralInfo.rejected, (state, action) => {
                state.dataIsLoading = false;
            })
            .addCase(fetchSupplierAccountManagerInfo.pending, (state) => {
                state.dataIsLoading = true;
            })
            .addCase(
                fetchSupplierAccountManagerInfo.fulfilled,
                (state, action) => {
                    state.supplierAccountManagerInfo =
                        action?.payload?.accountManager;
                    state.dataIsLoading = false;
                },
            )
            .addCase(
                fetchSupplierAccountManagerInfo.rejected,
                (state, action) => {
                    state.dataIsLoading = false;
                },
            )
            .addCase(updateSupplierAccountManagerInfo.pending, (state) => {
                state.dataIsLoading = true;
            })
            .addCase(
                updateSupplierAccountManagerInfo.fulfilled,
                (state, action) => {
                    state.supplierAccountManagerInfo =
                        action?.payload?.accountManager;
                    state.dataIsLoading = false;
                },
            )
            .addCase(
                updateSupplierAccountManagerInfo.rejected,
                (state, action) => {
                    state.dataIsLoading = false;
                },
            );
    },
});

export const { actions: supplierActions } = SupplierSlice;
export const { reducer: supplierReducer } = SupplierSlice;
