import { useSelector } from 'react-redux';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    List,
    ListItem,
    Typography,
    alpha,
} from '@mui/material';
import { Category } from 'entities/Category';
import { ArrowDropDown } from '@mui/icons-material';
import { categoryActions } from 'entities/Category/model/slices/CategorySlice';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { useCallback, useEffect, useState } from 'react';
import { getCatalogItemsAreLoading } from 'entities/CatalogItem/model/selectors/catalogItemSelectors';
import { useMatch, useNavigate } from 'react-router-dom';
import {
    getRouteGlobalCatalog,
    getRoutePropertiesCatalog,
    getRoutePropertiesCatalogInventory,
    getRoutePropertiesCatalogInventoryCategoriesSelected,
    getRoutePropertiesCatalogSelected,
    getRoutePropertiesGlobalCatalogSelected,
} from 'shared/const/router';
import {
    getSelectedPropertyCatalogId,
    getSelectedPropertyId,
} from 'entities/Property/model/selectors/PropertySelectors';
import { fetchSubCategories } from 'widgets/PropertyCatalog/models/services/fetchSubCategories/fetchSubCategories';
import {
    getSelectCategoryId,
    getSelectSubCategoryId,
} from 'entities/Category/model/selectors/categorySelectors';
import { GlobalCategory } from 'entities/Category/model/types/CategorySchema';
import { Loader } from 'shared/ui/Loader/Loader';
import { searchCatalogItemsActions } from 'features/SearchCatalogItems/model/slices/SearchCatalogItemsSlice';
import { TruncatedText } from 'shared/ui/TruncatedText/TruncatedText';
import { CategoryContextMenu } from 'features/CategoryContextMenu';
import { CategoryForm } from 'features/CategoryForm';
import { CategoryFormMode } from 'features/CategoryForm/module/types/categoryFormsTypes';
import { useTranslation } from 'react-i18next';

interface PropertyCatalogCategoryProps {
    category: Category;
    editable?: boolean;
}

const SubcategoryDropdowArrow = ({
    loading,
    onExpand,
}: {
    loading: boolean;
    onExpand: () => void;
}) => {
    return loading ? (
        <Loader />
    ) : (
        <ArrowDropDown
            onClick={onExpand}
            sx={(theme) => ({
                color: alpha(theme.palette.common?.black, 0.3),
            })}
        />
    );
};

export const PropertyCatalogCategory = ({
    category,
    editable,
}: PropertyCatalogCategoryProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const selectedPropertyId = useSelector(getSelectedPropertyId);
    const itemAreLoadingloading = useSelector(getCatalogItemsAreLoading);
    const selectedCategoryId = useSelector(getSelectCategoryId);
    const selectedSubCategoryId = useSelector(getSelectSubCategoryId);
    const catalogId = useSelector(getSelectedPropertyCatalogId);

    const [isExpanded, setIsExpanded] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [subCategories, setSubCategories] = useState<GlobalCategory[]>([]);
    const [loading, setLoading] = useState(false);

    const startEditing = useCallback(() => {
        setIsEditing(true);
    }, []);

    const stopEditing = useCallback(() => {
        setIsEditing(false);
    }, []);

    const isInventory = useMatch(
        getRoutePropertiesCatalogInventory(':propertyId') + '/*',
    );

    const isGlobalCatalog = useMatch(
        getRouteGlobalCatalog(':propertyId') + '/*',
    );

    const isCatalog = useMatch(getRoutePropertiesCatalog(':propertyId') + '/*');
    const clearItemTextSearch = useCallback(() => {
        dispatch(searchCatalogItemsActions.setGlobalCatItemSearchValue(''));
    }, [dispatch]);

    const mainCategoryIsActive =
        category.id === selectedCategoryId && !selectedSubCategoryId;

    useEffect(
        () => () => {
            dispatch(categoryActions.clearCategorySelection());
        },
        [dispatch],
    );

    const onSelect = useCallback(
        (category: Category) => () => {
            if (!itemAreLoadingloading) {
                dispatch(categoryActions.selectCategory(category));
                dispatch(categoryActions.selectSubCategory(null));

                if (isInventory) {
                    navigate(
                        getRoutePropertiesCatalogInventoryCategoriesSelected(
                            selectedPropertyId,
                            category.id,
                        ),
                    );
                } else if (isGlobalCatalog) {
                    navigate(
                        getRoutePropertiesGlobalCatalogSelected(
                            selectedPropertyId,
                            category.id,
                        ),
                    );
                    clearItemTextSearch();
                } else if (isCatalog) {
                    navigate(
                        getRoutePropertiesCatalogSelected(
                            selectedPropertyId,
                            category.id,
                        ),
                    );
                }
            }
        },
        [
            itemAreLoadingloading,
            dispatch,
            isInventory,
            isGlobalCatalog,
            isCatalog,
            navigate,
            selectedPropertyId,
            clearItemTextSearch,
        ],
    );

    const onExpand = async () => {
        if (loading) return;

        setLoading(true);
        if (!isExpanded) {
            const subcategories = await fetchSubCategories(category.id);
            setSubCategories(subcategories || []);
        }

        setIsExpanded((prevState) => !prevState);

        setLoading(false);
    };

    const onSelectSubCategory = (globalCategory: GlobalCategory) => () => {
        dispatch(categoryActions.selectSubCategory(globalCategory));
        dispatch(categoryActions.selectCategory(category));
        clearItemTextSearch();

        const newRoute = getRoutePropertiesGlobalCatalogSelected(
            selectedPropertyId,
            category.id,
        );

        navigate(newRoute);
    };

    return (
        <Box>
            <Accordion
                expanded={isExpanded}
                sx={{
                    bgcolor: mainCategoryIsActive ? 'grey.300' : undefined,
                }}
            >
                <AccordionSummary
                    expandIcon={
                        isGlobalCatalog ? (
                            <SubcategoryDropdowArrow
                                onExpand={onExpand}
                                loading={loading}
                            />
                        ) : undefined
                    }
                    sx={{
                        borderLeft: mainCategoryIsActive && '2px solid',
                        borderColor: mainCategoryIsActive && 'primary.main',
                    }}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        flex="1"
                        onClick={onSelect(category)}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            flex="1 1"
                        >
                            <Box display="flex">
                                <Box display="flex">
                                    <Box display="flex" alignItems="center">
                                        {category?.color && (
                                            <Box
                                                width="8px"
                                                height="8px"
                                                borderRadius="50%"
                                                mr="8px"
                                                sx={{
                                                    background: category.color,
                                                }}
                                            />
                                        )}

                                        <TruncatedText width="300px">
                                            {category.title}
                                        </TruncatedText>
                                    </Box>
                                    <Typography
                                        color={(theme) =>
                                            alpha(
                                                theme.palette.common?.black,
                                                0.5,
                                            )
                                        }
                                    >
                                        {category.glCode
                                            ? ` | ${category.glCode}`
                                            : ''}
                                    </Typography>
                                </Box>
                            </Box>
                            {editable && (
                                <CategoryContextMenu onEdit={startEditing} />
                            )}
                        </Box>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <List sx={{ p: 0 }}>
                        {subCategories.map((subCat) => {
                            const isActive =
                                subCat.uid === selectedSubCategoryId;
                            return (
                                <ListItem
                                    data-testid="Subcategories-name"
                                    sx={{
                                        pl: '16px',
                                        pr: '40px',
                                        borderTop: '1px solid',
                                        borderTopColor: 'grey.500',
                                        cursor: 'pointer',
                                        bgcolor: 'white',
                                        borderLeft: isActive && '2px solid',
                                        borderLeftColor: 'primary.main',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                    onClick={onSelectSubCategory(subCat)}
                                    key={subCat.catName}
                                >
                                    <Typography
                                        component="div"
                                        sx={(theme) => ({
                                            color: !isActive
                                                ? alpha(
                                                      theme.palette.text
                                                          .primary,
                                                      0.6,
                                                  )
                                                : undefined,
                                        })}
                                    >
                                        <TruncatedText width="400px">
                                            {subCat.catName}
                                        </TruncatedText>
                                    </Typography>
                                </ListItem>
                            );
                        })}
                    </List>
                </AccordionDetails>
            </Accordion>
            {isEditing && (
                <CategoryForm
                    mode={CategoryFormMode.EDIT}
                    catalogId={catalogId}
                    glCode={category.glCode}
                    categoryId={category.id}
                    title={category.title}
                    color={category.color}
                    onClose={stopEditing}
                    formTitle={t('Edit Category')}
                />
            )}
        </Box>
    );
};
