import { useTranslation } from 'react-i18next';
import { memo, useCallback } from 'react';
import { Button, Typography } from '@mui/material';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { addCatalogItemActions } from 'features/AddCatalogItem/model/slices/AddCatalogItemSlice';
import { AddCatalogItemDialog } from '../AddCatalogItemDialog/AddCatalogItemDialog';

interface AddToPropertyCatalogButtonProps {
    disabled: boolean;
}

export const AddToPropertyCatalogButton = memo(
    ({ disabled }: AddToPropertyCatalogButtonProps) => {
        const dispatch = useAppDispatch();
        const { t } = useTranslation();

        const onAddNewItem = useCallback(() => {
            dispatch(addCatalogItemActions.setShowDialog(true));
        }, [dispatch]);

        return (
            <>
                <Button
                    data-testid="AddToPropertyCatalogButton"
                    disabled={disabled}
                    variant="contained"
                    onClick={onAddNewItem}
                >
                    <Typography typography="openSans.button">
                        {t('Add to Property Catalog')}
                    </Typography>
                </Button>
                <AddCatalogItemDialog />
            </>
        );
    },
);
