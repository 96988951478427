import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';

import { Order } from 'entities/Order/model/types/OrderSchema';
import { ChangeOrderStatusParams } from '../types/changeOrderStatusSchema';

export const changeOrderStatusService = createAsyncThunk<
    Order,
    ChangeOrderStatusParams,
    ThunkConfig<string>
>('order/changeStatus', async ({ orderId, status }, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    try {
        const response = await extra.api.post<Order>(
            `/v1/orders/${orderId}/status`,
            { status },
        );

        return response.data;
    } catch (e) {
        if (e?.response?.data?.detail) {
            return rejectWithValue(e.response.data.detail);
        } else {
            return rejectWithValue('Something went wrong. Try reload.');
        }
    }
});
