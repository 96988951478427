import { Router } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getNavigationIsBlocked } from 'features/NavigationBlockModal/model/selectors/navigationBlockSelectors';
import { useNavigationBlock } from 'features/NavigationBlockModal/ui/NavigationBlockProvider/NavigationBlockProvider';
import { BrowserHistory } from 'history';
import {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';

type Props = {
    basename?: string;
    children: React.ReactNode;
    history: BrowserHistory;
};
export const CustomRouter = ({ basename, children, history }: Props) => {
    const ublockActionRef = useRef(() => {});
    const { validateNavBlock } = useNavigationBlock();
    const isBlocked = useSelector(getNavigationIsBlocked);

    const [state, setState] = useState({
        action: history.action,
        location: history.location,
    });

    useLayoutEffect(() => history.listen(setState), [history]);

    const onBlock = useCallback(() => {
        let unblock = history.block((tx) => {
            validateNavBlock(() => {
                unblock();
                tx.retry();
            }, true);
        });

        ublockActionRef.current = unblock;
    }, [history, validateNavBlock]);

    useEffect(() => {
        if (isBlocked) {
            onBlock();
        } else {
            ublockActionRef.current();
            validateNavBlock(() => {}, false);
            ublockActionRef.current = () => {};
        }
    }, [isBlocked, onBlock, validateNavBlock]);

    return (
        <Router
            basename={basename}
            location={state.location}
            navigator={history}
            navigationType={state.action}
        >
            {children}
        </Router>
    );
};
