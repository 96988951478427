import { Category } from '../model/types/CategorySchema';
import { set } from 'shared/lib/lodash/lodash';

export const mapCategories = (categories: Category[]) => {
    const parsedData = {};

    categories.forEach((cat) => {
        set(parsedData, cat.id, {
            ...cat,
        });
    });

    return parsedData;
};
