import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import {
    PatchCatalogItemsRequest,
    PatchCatalogItemsResponse,
} from 'entities/CatalogItem/model/types/CatalogItemSchema';
import { fetchInventoryItems } from 'widgets/InventoryTable/model/services/fetchCatalogItems/fetchInventoryItems';
import { inventoryTableActions } from 'widgets/InventoryTable/model/slices/InventoryTableSlice';

export const removeInventoryItemService = createAsyncThunk<
    PatchCatalogItemsResponse,
    PatchCatalogItemsRequest,
    ThunkConfig<any>
>('catalogItem/removeFromInventory', async ({ data, catalogId }, thunkApi) => {
    const { extra, rejectWithValue, dispatch } = thunkApi;

    try {
        const response = await extra.api.patch<PatchCatalogItemsResponse>(
            `/v1/catalogs/${catalogId}/items`,
            data,
        );

        dispatch(inventoryTableActions.clearSelection());
        await dispatch(fetchInventoryItems(catalogId));

        return response.data;
    } catch (e) {
        return rejectWithValue('error');
    }
});
