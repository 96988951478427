import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { orderTableActions } from 'widgets/OrdersTable/model/slices/OrderTableSlice';

export const deleteOrder = createAsyncThunk<{}, string, ThunkConfig<string>>(
    'order/deleteOrder',
    async (orderId, thunkApi) => {
        const { extra, rejectWithValue, dispatch } = thunkApi;
        try {
            await extra.api.delete(`/v1/orders/${orderId}`);

            dispatch(orderTableActions.deleteOrder(orderId));

            return { deleteOrder: orderId };
        } catch (e) {
            return rejectWithValue('error');
        }
    },
);
