/* eslint-disable max-len */
import { ReactNode, memo, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Box, Button, Dialog, Typography } from '@mui/material';
import { Loader } from 'shared/ui/Loader/Loader';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { changeOrderStatusService } from 'features/changeOrderStatus/model/services/changeOrderStatusService';
import { OrderStatuses } from 'entities/Order/model/consts/orderStatuses';
import { getRouteOrders } from 'shared/const/router';
import { DialogBoxContent } from 'shared/ui/DialogBoxContent/DialogBoxContent';

interface ChangeOrderStatusButtonProps {
    children: ReactNode;
    newStatus: OrderStatuses;
    messageOnSuccess: string;
    orderId: string;
    variant?: 'text' | 'contained' | 'outlined';
    disabled?: boolean;
    isRequiredPoNumberForOrder?: boolean;
}

export const ChangeOrderStatusButton = memo(
    ({
        newStatus,
        children,
        variant,
        orderId,
        messageOnSuccess,
        disabled,
        isRequiredPoNumberForOrder,
    }: ChangeOrderStatusButtonProps) => {
        const { t } = useTranslation();
        const dispatch = useAppDispatch();
        const navigate = useNavigate();

        const [isLoading, setIsLoading] = useState(false);
        const [showBlockModal, setShowBlockModal] = useState(false);

        const onCloseBlockModal = () => setShowBlockModal(false);

        const onChangeOrderStatus = useCallback(async () => {
            if (isRequiredPoNumberForOrder) {
                setShowBlockModal(true);
                return;
            }
            setIsLoading(true);

            const response = await dispatch(
                changeOrderStatusService({
                    orderId,
                    status: newStatus,
                }),
            );

            if (response.meta.requestStatus !== 'rejected') {
                toast(messageOnSuccess);
                navigate(getRouteOrders());
            } else {
                toast(response?.payload as string);
            }

            setIsLoading(false);
        }, [
            dispatch,
            messageOnSuccess,
            navigate,
            newStatus,
            orderId,
            isRequiredPoNumberForOrder,
        ]);

        return (
            <>
                <Button
                    startIcon={isLoading && <Loader color="inherit" />}
                    disabled={isLoading || disabled}
                    variant={variant || 'outlined'}
                    onClick={onChangeOrderStatus}
                >
                    {children}
                </Button>
                {showBlockModal && (
                    <Dialog open onClose={onCloseBlockModal}>
                        <DialogBoxContent
                            onClose={onCloseBlockModal}
                            buttons={
                                <Box
                                    display="flex"
                                    justifyContent="end"
                                    width="100%"
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={onCloseBlockModal}
                                    >
                                        {t('Ok')}
                                    </Button>
                                </Box>
                            }
                        >
                            <Box maxWidth="360px">
                                <Typography
                                    typography={'poppins.subtitle2'}
                                    mb="18px"
                                >
                                    {t('Missing Purchase Order(s)')}
                                </Typography>
                                <Typography
                                    typography={'openSans.body2'}
                                    mb="18px"
                                >
                                    {t(
                                        'Please be sure a purchase order has been provided for all suppliers before proceeding with the order.',
                                    )}
                                </Typography>
                            </Box>
                        </DialogBoxContent>
                    </Dialog>
                )}
            </>
        );
    },
);
