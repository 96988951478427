import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchField } from 'shared/ui/SearchField/SearchField';

export const OrdersTableSearch = () => {
    const { t } = useTranslation();
    const [search, setSearch] = useState('');
    return (
        <SearchField
            value={search}
            setValue={setSearch}
            placeholder={t('Search')}
        />
    );
};
