import { useTranslation } from 'react-i18next';
import { memo, useState } from 'react';
import { Button } from '@mui/material';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import { getSelectedOrderItemProposal } from 'features/UpdateOrderItemProposal';
import { editOrderItem } from 'features/editItemsInOrder/model/services/editOrderItem/editOrderItem';
import { Loader } from 'shared/ui/Loader/Loader';

interface UpdateOrderItemProposalButtonProps {
    selectedOrderId: string;
    itemIdToEdit: string;
}

export const UpdateOrderItemProposalButton = memo(
    ({ selectedOrderId, itemIdToEdit }: UpdateOrderItemProposalButtonProps) => {
        const { t } = useTranslation();
        const selectedProposal = useSelector(getSelectedOrderItemProposal);
        const [isLoading, setIsLoading] = useState(false);

        const dispatch = useAppDispatch();

        const update = async () => {
            setIsLoading(true);
            const dataToEdit = {
                orderId: selectedOrderId,
                itemId: itemIdToEdit,
                selectedProposal: {
                    uid: selectedProposal.uid,
                    name: selectedProposal.name,
                    price: selectedProposal.price,
                },
            };

            await dispatch(editOrderItem(dataToEdit));

            setIsLoading(false);
        };

        return (
            <Button
                startIcon={isLoading && <Loader color="inherit" />}
                disabled={isLoading}
                variant="contained"
                onClick={update}
            >
                {t('Save')}
            </Button>
        );
    },
);
