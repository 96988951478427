import { memo, useCallback, useState } from 'react';
import { Menu } from '@mui/material';
import { AccountMenuItems } from './AccountMenuItems';
import { UserAvatar } from 'shared/ui/UserAvatar/UserAvatar';
import { useSelector } from 'react-redux';
import { getUserData } from 'entities/User';
import { getProfileIsLoading } from 'entities/User/model/selectors/userSelectors';

export const AccountMenu = memo(() => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const user = useSelector(getUserData);
    const isLoading = useSelector(getProfileIsLoading);

    const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    return (
        <>
            <UserAvatar
                user={user}
                isLoading={isLoading}
                onClick={handleClick}
            />
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.1))',
                            minWidth: '200px',
                            borderRadius: '6px',
                            mt: 1.5,
                        },
                    },
                }}
                sx={{
                    padding: 0,
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <AccountMenuItems />
            </Menu>
        </>
    );
});
