import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { SupplierAccountInfoResponseSchema } from '../types/SupplierSchema';

export const updateSupplierAccountManagerInfo = createAsyncThunk<
    SupplierAccountInfoResponseSchema,
    {
        supplierId: string;
        propertyId: string;
        updateData: { [key: string]: string | { number: string } };
        type: string;
    },
    ThunkConfig<string>
>(
    'supplier/update-account-info',
    async ({ supplierId, propertyId, updateData, type }, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi;
        try {
            const response =
                await extra.api.patch<SupplierAccountInfoResponseSchema>(
                    `/v1/properties/${propertyId}/vendors/${supplierId}`,
                    { [type]: updateData },
                );

            return response.data;
        } catch (e) {
            return rejectWithValue('error');
        }
    },
);
