// These floats are necessary for making sticky some containers in App

const headerHeight = 64;
const innerContentPadding = 24;
const innerPagePadding = 24;
const heightFromTopOfPageContentTillItemListStart = 240;
const heightFromTopOfPageContentTillItemSearchList = 200;
const heightOffsetForItems = 285;

export const headerHeightStyle = `${headerHeight}px`;

const innerNavHeightOffset = headerHeight + innerContentPadding * 2;

const innerPageHeightOffset = innerNavHeightOffset + innerPagePadding * 2;

const itemsContainerMaxHeightOffset =
    innerNavHeightOffset + heightFromTopOfPageContentTillItemListStart;

export const itemsContainerHeightOffset =
    innerNavHeightOffset + heightFromTopOfPageContentTillItemSearchList;

export const contentHeight = `calc(100vh - ${headerHeightStyle})`;
export const innerNavHeight = `calc(100vh - ${innerNavHeightOffset}px)`;
export const innerPageHeight = `calc(100vh - ${innerPageHeightOffset}px)`;
export const itemsContainerHeight = `calc(100vh - ${heightOffsetForItems}px)`;
export const itemsContainerMaxHeight = `calc(100vh - ${itemsContainerMaxHeightOffset}px)`;
export const inventoryItemsContainerMaxHeight = `calc(100vh - 529px)`;

export const innerNavFixPosition = `calc(${
    headerHeight + innerContentPadding
}px)`;

export const maxWidthContent = '1092px';
