import { memo } from 'react';
import { Box, Typography, Link } from '@mui/material';

interface PropertyCardRowProps {
    name: string;
    value: string | number;
    minimized?: boolean;
    bordered?: boolean;
    isEmail?: boolean;
}
export const PropertyCardRow = memo(
    ({ name, value, isEmail, minimized, bordered }: PropertyCardRowProps) => {
        const showEmail = isEmail && value ? value : '-';

        return (
            <Box
                p={!minimized ? '15px 0 17px' : '6px 0 7px'}
                display="flex"
                borderBottom={bordered ? '1px solid' : undefined}
                borderColor="divider"
                justifyContent="space-between"
                data-testid={`Item-details-${name}`}
            >
                <Typography
                    typography={
                        minimized ? 'openSans.body2' : 'openSans.subtitle1'
                    }
                    color="text.primary"
                >
                    {name}
                </Typography>
                <Typography
                    component={isEmail && value ? Link : undefined}
                    noWrap
                    maxWidth="340px"
                    color={isEmail && value ? 'blue.500' : 'text.secondary'}
                    href={isEmail && value ? `mailto: ${value}` : undefined}
                    rel="noopener noreferrer"
                    typography={
                        minimized ? 'openSans.body2' : 'openSans.subtitle1'
                    }
                    borderColor="blue.500"
                    textAlign="right"
                >
                    {isEmail ? showEmail : value}
                </Typography>
            </Box>
        );
    },
);
