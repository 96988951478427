import { StateSchema } from 'app/providers/StoreProvider';

export const getInventoryTableSelectedItems = (state: StateSchema) =>
    state.inventoryTable.selectedItems;

export const getInventoryItemsTotal = (state: StateSchema) =>
    Object.values(state.inventoryTable.selectedItems || {}).length;

export const getInventoryTableItems = (state: StateSchema) =>
    state.inventoryTable.items;

export const getInventoryTableItemsLoading = (state: StateSchema) =>
    state.inventoryTable.isLoading;
