import { Box, Tab, Tabs } from '@mui/material';
import {
    OrderStatuses,
    OrderStatusesCamel,
    getOrderStatusForRoute,
    useGetOrderStatusTitle,
} from 'entities/Order/model/consts/orderStatuses';
import { getOrdersSearchParamsStatus } from 'entities/Order/model/selectors/ordersSelectors';
import { orderActions } from 'entities/Order/model/slices/OrderSlice';
import { SyntheticEvent, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { getOrdersTableCounters } from '../model/selectors/catalogItemSelectors';
import { orderTableActions } from '../model/slices/OrderTableSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { getRouteOrdersTableByStatus } from 'shared/const/router';

export const OrdersTableTabs = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const counters = useSelector(getOrdersTableCounters);

    const currentStatus = useSelector(getOrdersSearchParamsStatus);
    const pendingApproval = useGetOrderStatusTitle(
        OrderStatuses.PENDING_APPROVAL,
    );
    const approved = useGetOrderStatusTitle(OrderStatuses.APPROVED);
    const draft = useGetOrderStatusTitle(OrderStatuses.DRAFT);
    const reject = useGetOrderStatusTitle(OrderStatuses.REJECTED);
    const inReview = useGetOrderStatusTitle(OrderStatuses.IN_REVIEW);
    const processing = useGetOrderStatusTitle(OrderStatuses.PROCESSING);
    const shipped = useGetOrderStatusTitle(OrderStatuses.SHIPPED);
    const invoiced = useGetOrderStatusTitle(OrderStatuses.INVOICED);

    const onTabChange = (
        event: SyntheticEvent<Element, Event>,
        value: OrderStatuses,
    ) => {
        dispatch(orderActions.setSearchParamsStatus(value));
        dispatch(orderTableActions.clearPaginationForTabChange());
        navigate(
            getRouteOrdersTableByStatus(
                params?.propertyId,
                getOrderStatusForRoute(value),
            ),
        );
    };

    const schema = useMemo(
        () => [
            {
                label: draft,
                value: OrderStatuses.DRAFT,
                key: OrderStatusesCamel.DRAFT,
            },
            {
                label: inReview,
                value: OrderStatuses.IN_REVIEW,
                key: OrderStatusesCamel.IN_REVIEW,
                show: true,
            },
            {
                label: pendingApproval,
                value: OrderStatuses.PENDING_APPROVAL,
                key: OrderStatusesCamel.PENDING_APPROVAL,
                show: true,
            },
            {
                label: approved,
                value: OrderStatuses.APPROVED,
                key: OrderStatusesCamel.APPROVED,
            },
            {
                label: reject,
                value: OrderStatuses.REJECTED,
                key: OrderStatusesCamel.REJECTED,
            },
            {
                label: processing,
                value: OrderStatuses.PROCESSING,
                key: OrderStatusesCamel.PROCESSING,
            },
            {
                label: shipped,
                value: OrderStatuses.SHIPPED,
                key: OrderStatusesCamel.SHIPPED,
            },
            {
                label: invoiced,
                value: OrderStatuses.INVOICED,
                key: OrderStatusesCamel.INVOICED,
            },
        ],
        [
            approved,
            draft,
            inReview,
            pendingApproval,
            reject,
            processing,
            shipped,
            invoiced,
        ],
    );

    return (
        <Box
            mb="24px"
            borderBottom="1px solid"
            borderColor="grey.500"
            data-testid="OrderTableTabs"
        >
            <Tabs value={currentStatus} onChange={onTabChange}>
                {schema.map((item) => {
                    const count = counters?.[item.key];
                    const getLabelWithCounter = (label: string) => {
                        let currentCount;
                        if (count) {
                            if (count > 99) {
                                currentCount = '(99+)';
                            }
                            currentCount = `(${count})`;
                        } else {
                            currentCount = '(0)';
                        }
                        return `${label} ${item?.show ? currentCount : ''}`;
                    };

                    const label = getLabelWithCounter(item.label);

                    return (
                        <Tab
                            key={item.key}
                            label={label}
                            value={item.value}
                            data-testid="OrderTableTabs.Tab"
                        />
                    );
                })}
            </Tabs>
        </Box>
    );
};
