import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UpdateOrderItemProposalSchema } from '../types/UpdateOrderItemProposalSchema';
import { SelectedProposal } from 'entities/Proposal/model/types/ProposalSchema';

const initialState: UpdateOrderItemProposalSchema = {
    selectedProposal: null,
};

export const UpdateOrderItemProposalSlice = createSlice({
    name: 'UpdateOrderItemProposal',
    initialState,
    reducers: {
        setProposal: (state, action: PayloadAction<SelectedProposal>) => {
            state.selectedProposal = action.payload;
        },
    },
});

export const { actions: updateOrderItemProposalActions } =
    UpdateOrderItemProposalSlice;
export const { reducer: updateOrderItemProposalReducer } =
    UpdateOrderItemProposalSlice;
