import { StateSchema } from 'app/providers/StoreProvider';

export const getOrdersSearchParams = (state: StateSchema) =>
    state.order?.searchParams;

export const getOrdersSearchParamsStatus = (state: StateSchema) =>
    state.order?.searchParams?.status;

export const getSelectedOrder = (state: StateSchema) =>
    state.order?.selectedData;

export const getSelectedOrderIsLoading = (state: StateSchema) =>
    state.order?.dataIsLoading;

export const getSelectedPoNumberIsLoading = (state: StateSchema) =>
    state.order?.poNumberIsLoading;

export const getSelectedOrderId = (state: StateSchema) =>
    state.order?.selectedData?.id;

export const getSelectedOrderPropertyId = (state: StateSchema) =>
    state.order?.selectedData?.property;

export const getSelectedOrderItems = (state: StateSchema) =>
    state.order?.selectedData?.items;

export const getSelectedOrderType = (state: StateSchema) =>
    state.order?.selectedData?.orderType === 'RENOVATION'
        ? 'Renovations'
        : 'Operations';

export const getSelectedOrderStatus = (state: StateSchema) =>
    state.order?.selectedData?.status;

export const getSelectedOrderSummary = (state: StateSchema) =>
    state.order.orderSummary;

export const getSelectedOrderCreatorName = (state: StateSchema) => {
    const creator = state.order?.selectedData?.creator;
    return `${creator?.firstName || ''} ${creator?.lastName || ''} `;
};
