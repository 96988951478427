import { Box, Skeleton, Typography, alpha } from '@mui/material';
import { getSelectedCatalogItemsTotal } from 'entities/CatalogItem/model/selectors/catalogItemSelectors';
import { getLocalCategoriesList } from 'entities/Category';
import { getUserCanSeePrices } from 'entities/User/model/selectors/userPermissionsSeletors';
import { getCurrentOrderSummary } from 'pages/OrderViewPage/model/selectors/orderViewPageSelectors';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { OrderViewPageDetails } from './OrderViewPageDetails';
import { OrderProposalFilter } from 'features/OrderProposalFilter';
import { UserRole, getUserRole } from 'entities/User';

interface OrderViewPageInfoProps {
    summaryByCategories: Record<
        string,
        {
            count: number;
            cost: string;
        }
    >;
    isLoading: boolean;
}

export const OrderViewPageInfo = ({
    summaryByCategories,
    isLoading,
}: OrderViewPageInfoProps) => {
    const { t } = useTranslation();

    const currentUserRole = useSelector(getUserRole);
    const isTechnicianUser =
        currentUserRole === UserRole.MAINTENANCE_TECHNICIAN;

    const totalCategories = Object.values(summaryByCategories).length;

    const categories = useSelector(getLocalCategoriesList);
    const orderSummary = useSelector(getCurrentOrderSummary);
    const canSeePrices = useSelector(getUserCanSeePrices);
    const totalItemsCount = useSelector(getSelectedCatalogItemsTotal);

    return (
        <Box>
            <OrderViewPageDetails />
            <Box
                mt="16px"
                borderRadius="12px"
                sx={(theme) => ({
                    bgcolor: alpha(theme.palette.grey[500], 0.5),
                })}
                p="16px"
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    {!isLoading ? (
                        <Typography
                            sx={(theme) => ({
                                color: alpha(theme.palette.common.black, 0.5),
                            })}
                            mb="8px"
                        >
                            {t('Order Summary')}
                        </Typography>
                    ) : (
                        <>
                            <Skeleton width="240px" height="32px" />
                            <Skeleton width="80px" height="32px" />
                        </>
                    )}
                </Box>

                {!isLoading ? (
                    <Box>
                        <Box
                            mb="8px"
                            display="flex"
                            justifyContent="space-between"
                        >
                            <Box display="inline-flex" gap="4px">
                                <Typography
                                    typography={'openSans.subtitle1Medium'}
                                >
                                    {t('Total Items')} ({totalItemsCount})
                                </Typography>
                                <Typography
                                    typography={'openSans.subtitle1Medium'}
                                >
                                    {t('in Categories')} ({totalCategories})
                                </Typography>
                            </Box>
                            <Box>
                                {canSeePrices && (
                                    <Typography typography="openSans.subtitle1Medium">
                                        ${orderSummary}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="column" gap="8px">
                            {Object.entries(summaryByCategories).map(
                                ([id, info]) => (
                                    <Box
                                        key={id}
                                        display="flex"
                                        justifyContent="space-between"
                                    >
                                        <Box display="flex" gap="4px">
                                            <Typography>
                                                {categories?.[id]?.title}
                                            </Typography>
                                            <Typography
                                                color={(theme) =>
                                                    alpha(
                                                        theme.palette.common
                                                            .black,
                                                        0.5,
                                                    )
                                                }
                                            >
                                                {categories?.[id]?.glCode
                                                    ? ` | ${categories?.[id]?.glCode}`
                                                    : null}
                                            </Typography>
                                        </Box>
                                        {canSeePrices && (
                                            <Typography>
                                                ${info.cost || '0.00'}
                                            </Typography>
                                        )}
                                    </Box>
                                ),
                            )}
                        </Box>
                        {!isTechnicianUser && <OrderProposalFilter />}
                    </Box>
                ) : (
                    <Skeleton width="180px" height="20px" />
                )}
            </Box>
        </Box>
    );
};
