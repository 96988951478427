import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';

import { catalogItemActions } from 'entities/CatalogItem/model/slices/CatalogItemSlice';
import {
    PatchCatalogItemsRequest,
    PatchCatalogItemsResponse,
} from 'entities/CatalogItem/model/types/CatalogItemSchema';
import { toast } from 'react-toastify';
import { set } from 'shared/lib/lodash/lodash';

export const moveToInventoryItemService = createAsyncThunk<
    PatchCatalogItemsResponse,
    PatchCatalogItemsRequest,
    ThunkConfig<any>
>(
    'catalogItem/moveToInventory',
    async ({ data, catalogId, isSingle }, thunkApi) => {
        const { extra, rejectWithValue, dispatch } = thunkApi;

        try {
            const response = await extra.api.patch<PatchCatalogItemsResponse>(
                `/v1/catalogs/${catalogId}/items`,
                data,
            );

            const dataToUpdate = {};

            response.data.patched.forEach((item) => {
                set(dataToUpdate, item.id, item);
            });

            dispatch(catalogItemActions.updateItems(dataToUpdate));
            dispatch(catalogItemActions.removeAllSelection());

            isSingle
                ? toast('Item added to inventory')
                : toast('Items added to inventory');

            return response.data;
        } catch (e) {
            return rejectWithValue('error');
        }
    },
);
