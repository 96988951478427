import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import {
    RemoveCatalogItemRequestBody,
    RemoveCatalogItemResponse,
} from '../types/RemoveCatalogItemSchema';
import { catalogItemActions } from 'entities/CatalogItem/model/slices/CatalogItemSlice';
import { toast } from 'react-toastify';

export const removeCatalogItemService = createAsyncThunk<
    RemoveCatalogItemResponse,
    RemoveCatalogItemRequestBody,
    ThunkConfig<any>
>(
    'catalogItem/removeFromCatalog',
    async ({ data, catalogId, isSingle }, thunkApi) => {
        const { extra, rejectWithValue, dispatch } = thunkApi;

        try {
            const response = await extra.api.delete<RemoveCatalogItemResponse>(
                `/v1/catalogs/${catalogId}/items`,
                { data },
            );

            const dataToRemove = response.data.removed.map(
                (item) => item.itemUuid,
            );

            if (isSingle) {
                if (dataToRemove.length) {
                    dispatch(
                        catalogItemActions.removeSingleItemByUidFromSelection(
                            dataToRemove[0],
                        ),
                    );
                }

                dispatch(catalogItemActions.closeDetailedItem());
            } else {
                dispatch(catalogItemActions.removeAllSelection());
            }

            dispatch(catalogItemActions.removeMultipleItemsByUid(dataToRemove));

            toast(
                isSingle
                    ? 'Item removed from catalog'
                    : 'Items removed from catalog',
            );

            return response.data;
        } catch (e) {
            return rejectWithValue('error');
        }
    },
);
