import { snakeCase } from 'shared/lib/lodash/lodash';

export const convertKeysToSnakeCase = (
    obj: Record<string, any>,
): Record<string, any> => {
    if (typeof obj !== 'object' || !obj) {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map((item) => convertKeysToSnakeCase(item));
    }

    const newObj: Record<string, any> = {};

    for (const [key, value] of Object.entries(obj)) {
        const newKey = snakeCase(key);
        const newValue = convertKeysToSnakeCase(value);
        newObj[newKey] = newValue;
    }

    return newObj;
};
