import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { DeleteItemsInOrderRequestParams } from '../../types/removeItemsInOrderSchema';
import { CatalogItemInOrder } from 'entities/CatalogItem/model/types/CatalogItemSchema';

export const deleteOrderItem = createAsyncThunk<
    CatalogItemInOrder,
    DeleteItemsInOrderRequestParams,
    ThunkConfig<string>
>('order/deleteOrderItem', async ({ orderId, itemId }, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    try {
        const response = await extra.api.delete<CatalogItemInOrder>(
            `/v1/orders/${orderId}/items/${itemId}`,
        );

        return response.data;
    } catch (e) {
        return rejectWithValue('error');
    }
});
