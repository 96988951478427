import { useTranslation } from 'react-i18next';
import { kebabCase } from 'shared/lib/lodash/lodash';

export enum OrderStatuses {
    DRAFT = 'DRAFT',
    IN_REVIEW = 'IN_REVIEW',
    PENDING_APPROVAL = 'PENDING_APPROVAL',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    PROCESSING = 'PROCESSING',
    SHIPPED = 'SHIPPED',
    INVOICED = 'INVOICED',
}

export enum OrderStatusesCamel {
    DRAFT = 'draft',
    IN_REVIEW = 'inReview',
    PENDING_APPROVAL = 'pendingApproval',
    APPROVED = 'approved',
    REJECTED = 'rejected',
    PROCESSING = 'processing',
    SHIPPED = 'Shipped',
    INVOICED = 'Invoiced',
}

const OrderStatusesKebab: Record<OrderStatuses, string> = {
    [OrderStatuses.DRAFT]: kebabCase(OrderStatuses.DRAFT),
    [OrderStatuses.IN_REVIEW]: kebabCase(OrderStatuses.IN_REVIEW),
    [OrderStatuses.PENDING_APPROVAL]: kebabCase(OrderStatuses.PENDING_APPROVAL),
    [OrderStatuses.APPROVED]: kebabCase(OrderStatuses.APPROVED),
    [OrderStatuses.REJECTED]: kebabCase(OrderStatuses.REJECTED),
    [OrderStatuses.PROCESSING]: kebabCase(OrderStatuses.PROCESSING),
    [OrderStatuses.SHIPPED]: kebabCase(OrderStatuses.SHIPPED),
    [OrderStatuses.INVOICED]: kebabCase(OrderStatuses.INVOICED),
};

const proccesingStatuses = [
    'IN_PROGRESS',
    'WAITING_CONFIRMATION',
    'CONFIRMED',
    'SUBMITTED',
    'READY_TO_SHIP',
    'ATTENTION_REQUIRED',
];

const invoicedStatuses = ['INVOICED', 'COMPLETE'];

export const getOrderStatusForRoute = (orderStatus: OrderStatuses) => {
    return OrderStatusesKebab?.[orderStatus];
};

export const getOrderStatusFromRoute = (
    orderStatusKebab: string,
): OrderStatuses => {
    for (const [orderStatus, kebabStaus] of Object.entries(
        OrderStatusesKebab,
    )) {
        if (kebabStaus === orderStatusKebab) {
            return orderStatus as OrderStatuses;
        }
    }
    return null;
};

export const useGetOrderStatusTitle = (orderStatus: OrderStatuses) => {
    const { t } = useTranslation();
    let convertStatus = orderStatus;

    if (proccesingStatuses.includes(orderStatus)) {
        convertStatus = OrderStatuses.PROCESSING;
    } else if (invoicedStatuses.includes(orderStatus)) {
        convertStatus = OrderStatuses.INVOICED;
    }
    const titles = {
        [OrderStatuses.DRAFT]: t('Draft'),
        [OrderStatuses.IN_REVIEW]: t('Supervisor Review'),
        [OrderStatuses.PENDING_APPROVAL]: t('Manager Review'),
        [OrderStatuses.APPROVED]: t('Approved'),
        [OrderStatuses.REJECTED]: t('Rejected'),
        [OrderStatuses.PROCESSING]: t('Processing'),
        [OrderStatuses.SHIPPED]: t('Shipped'),
        [OrderStatuses.INVOICED]: t('Invoiced'),
    };

    return titles?.[convertStatus];
};
