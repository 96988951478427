import { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    getUserInited,
    userActions,
    fetchUser,
    getUserIsAuthed,
} from 'entities/User';
import { Box } from '@mui/material';
import { isMobile } from 'shared/const/platforms';
import { useInitialEffect } from 'shared/lib/hooks/useInitialEffect';
import { usePublicPageDetector } from 'shared/lib/hooks/usePublicPageDetector';
import { AppRouter } from './providers/router';
import { AuthService } from 'shared/services/authService';
import { Header } from 'widgets/Header/ui/Header';
import { MobileOverlay } from 'widgets/MobileOverlay';
import { Navbar } from 'features/Navbar';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { Toast } from 'shared/ui/Toast/Toast';
import { getUserRoleIsAcceptable } from 'entities/User/model/selectors/userSelectors';
import { USER_ACCESS_TOKEN } from 'shared/const/localstorage';
import { getSelectedPropertyId } from 'entities/Property/model/selectors/PropertySelectors';
import { orderTableActions } from 'widgets/OrdersTable/model/slices/OrderTableSlice';

const App = () => {
    const dispatch = useAppDispatch();
    const inited = useSelector(getUserInited);
    const auth = useSelector(getUserIsAuthed);
    const haveAccessToApp = useSelector(getUserRoleIsAcceptable);
    const selectedPropertyId = useSelector(getSelectedPropertyId);
    const isPublicPage = usePublicPageDetector();

    const nav = inited && auth && haveAccessToApp;

    useEffect(() => {
        if (selectedPropertyId) {
            dispatch(orderTableActions.clearSorting());
        }
    }, [dispatch, selectedPropertyId]);

    useInitialEffect(async () => {
        try {
            const authService = new AuthService();
            const accessToken = localStorage.getItem(USER_ACCESS_TOKEN);

            await authService.getUser().then(async (user) => {
                if (user) {
                    dispatch(userActions.login(user.access_token));
                }
            });

            setTimeout(async () => {
                let response = null;

                if (accessToken) {
                    response = await dispatch(fetchUser());
                }

                if (response && response.meta.requestStatus === 'fulfilled') {
                    dispatch(userActions.authenticateUser());
                }

                dispatch(userActions.initAuthData());
            });
        } catch (error) {
            console.log('initialize app error', error);
        }
    });

    return (
        <Box minHeight="100vh" display="flex">
            {!isPublicPage && isMobile() && <MobileOverlay />}
            <Box flexGrow={1}>
                <Suspense fallback="">
                    {!isPublicPage && auth && <Header />}
                    <Box bgcolor="default" display="flex">
                        {!isPublicPage && nav && <Navbar />}
                        <Box flex="1 1">{inited && <AppRouter />}</Box>
                    </Box>
                </Suspense>
            </Box>
            <Toast />
        </Box>
    );
};

export default App;
