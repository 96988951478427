import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { NotificationResponse } from '../../types/NotificationsSchema';
import { notificationPageSize } from '../../conts/notificationsConst';

export const fetchNotifications = createAsyncThunk<
    NotificationResponse,
    void,
    ThunkConfig<string>
>('notifications/fetchNotifications', async (_, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    try {
        const response = await extra.api.get<NotificationResponse>(
            `/v1/users/notifications`,
            {
                params: { pageSize: notificationPageSize, page: 1 },
            },
        );

        return response.data;
    } catch (e) {
        return rejectWithValue('error');
    }
});
