import { StateSchema } from 'app/providers/StoreProvider';
import { OrderStatuses } from '../consts/orderStatuses';
import {
    getUserCanApproveOrder,
    getUserCanEditInReviewOrder,
    getUserCanEditPendingApprovalOrder,
    getUserCanMoveOrderToPendingApproval,
} from 'entities/User/model/selectors/userPermissionsSeletors';
import { getSelectedOrderStatus } from './ordersSelectors';
import { UserRole, getUserRole } from 'entities/User';

export const getCanEditDueToExternalPermissions = (state: StateSchema) => {
    const selectedOrderStatus = getSelectedOrderStatus(state);
    const canEditPendingApprovalOrder =
        getUserCanEditPendingApprovalOrder(state);

    const canEditInReviewOrder = getUserCanEditInReviewOrder(state);

    if (
        !selectedOrderStatus &&
        (!canEditPendingApprovalOrder || !canEditInReviewOrder)
    ) {
        return false;
    }

    const canEditPendingApproval =
        selectedOrderStatus === OrderStatuses.PENDING_APPROVAL &&
        canEditPendingApprovalOrder;

    const canEditInReview =
        selectedOrderStatus === OrderStatuses.IN_REVIEW && canEditInReviewOrder;

    return canEditPendingApproval || canEditInReview;
};

export const getCanEditOwnSelectedOrder = (state: StateSchema) => {
    const selectedOrderStatus = getSelectedOrderStatus(state);
    return selectedOrderStatus === OrderStatuses.DRAFT;
};

export const getCanEditSelectedOrder = (state: StateSchema) => {
    const selectedOrderStatus = getSelectedOrderStatus(state);

    const canEditDueExternalPermissions =
        getCanEditDueToExternalPermissions(state);

    return (
        canEditDueExternalPermissions ||
        selectedOrderStatus === OrderStatuses.DRAFT
    );
};

export const getDisableChangeSupplierAndDeleteItems = (state: StateSchema) => {
    const selectedOrderStatus = getSelectedOrderStatus(state);
    const currentUserRole = getUserRole(state);

    if (
        selectedOrderStatus === OrderStatuses.APPROVED ||
        selectedOrderStatus === OrderStatuses.REJECTED
    ) {
        return true;
    }

    const isPermissionMoreThanSupervisor =
        currentUserRole === UserRole.PROPERTY_MANAGER ||
        currentUserRole === UserRole.REOPS_ADMIN ||
        currentUserRole === UserRole.PMC_ADMIN;

    if (
        !isPermissionMoreThanSupervisor &&
        selectedOrderStatus === OrderStatuses.DRAFT
    ) {
        return false;
    }
    if (
        !isPermissionMoreThanSupervisor &&
        (selectedOrderStatus === OrderStatuses.DRAFT ||
            selectedOrderStatus === OrderStatuses.IN_REVIEW)
    ) {
        return false;
    }
    if (
        isPermissionMoreThanSupervisor &&
        (selectedOrderStatus === OrderStatuses.DRAFT ||
            selectedOrderStatus === OrderStatuses.IN_REVIEW ||
            selectedOrderStatus === OrderStatuses.PENDING_APPROVAL)
    ) {
        return false;
    }

    return true;
};

export const getCanRejectSeletedOrder = (state: StateSchema) => {
    const selectedOrderStatus = getSelectedOrderStatus(state);
    const currentUserRole = getUserRole(state);

    if (
        selectedOrderStatus === OrderStatuses.IN_REVIEW &&
        currentUserRole === UserRole.MAINTENANCE_SUPERVISOR
    ) {
        return true;
    }

    const isPermissionMoreThanSupervisor =
        currentUserRole !== UserRole.MAINTENANCE_TECHNICIAN &&
        currentUserRole !== UserRole.MAINTENANCE_SUPERVISOR;
    const isReviewOrPendingStatus =
        selectedOrderStatus === OrderStatuses.PENDING_APPROVAL ||
        selectedOrderStatus === OrderStatuses.IN_REVIEW;

    if (
        currentUserRole !== UserRole.MAINTENANCE_TECHNICIAN &&
        selectedOrderStatus === OrderStatuses.DRAFT
    ) {
        return true;
    }

    if (isPermissionMoreThanSupervisor && isReviewOrPendingStatus) {
        return true;
    }
    return false;
};

export const getCanEditCountOfQty = (state: StateSchema) => {
    const selectedOrderStatus = getSelectedOrderStatus(state);
    const currentUserRole = getUserRole(state);

    if (
        currentUserRole === UserRole.MAINTENANCE_TECHNICIAN &&
        selectedOrderStatus === OrderStatuses.DRAFT
    ) {
        return true;
    }
    if (
        currentUserRole === UserRole.MAINTENANCE_SUPERVISOR &&
        (selectedOrderStatus === OrderStatuses.DRAFT ||
            selectedOrderStatus === OrderStatuses.IN_REVIEW)
    ) {
        return true;
    }
    if (
        currentUserRole !== UserRole.MAINTENANCE_TECHNICIAN &&
        currentUserRole !== UserRole.MAINTENANCE_SUPERVISOR &&
        selectedOrderStatus !== OrderStatuses.APPROVED &&
        selectedOrderStatus !== OrderStatuses.REJECTED
    ) {
        return true;
    }

    return false;
};

export const getCanApproveSelectedOrder = (state: StateSchema) => {
    const selectedOrderStatus = getSelectedOrderStatus(state);
    const currentUserRole = getUserRole(state);

    const rolesCanApproveFromDraftOrReview = [
        UserRole.PMC_ADMIN,
        UserRole.PROPERTY_MANAGER,
    ];

    return (
        (((selectedOrderStatus === OrderStatuses.DRAFT ||
            selectedOrderStatus === OrderStatuses.IN_REVIEW) &&
            rolesCanApproveFromDraftOrReview.includes(currentUserRole)) ||
            selectedOrderStatus === OrderStatuses.PENDING_APPROVAL) &&
        getUserCanApproveOrder(state)
    );
};

export const getCanMoveToPendingApprovalSelectedOrder = (
    state: StateSchema,
) => {
    const selectedOrderStatus = getSelectedOrderStatus(state);
    const currentUserRole = getUserRole(state);
    const canMoveToPendingApproval =
        getUserCanMoveOrderToPendingApproval(state);

    return (
        ((selectedOrderStatus === OrderStatuses.DRAFT &&
            currentUserRole === UserRole.MAINTENANCE_SUPERVISOR) ||
            selectedOrderStatus === OrderStatuses.IN_REVIEW) &&
        canMoveToPendingApproval
    );
};

export const getCanMoveToSupervicerReviewSelectedOrder = (
    state: StateSchema,
) => {
    const selectedOrderStatus = getSelectedOrderStatus(state);
    const currentUserRole = getUserRole(state);

    return (
        selectedOrderStatus === OrderStatuses.DRAFT &&
        currentUserRole === UserRole.MAINTENANCE_TECHNICIAN
    );
};

export const getAdminOrManagerRoles = (state: StateSchema) => {
    const currentUserRole = getUserRole(state);
    return (
        currentUserRole === UserRole.PMC_ADMIN ||
        currentUserRole === UserRole.PROPERTY_MANAGER
    );
};
