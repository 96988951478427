import { set, startCase } from 'shared/lib/lodash/lodash';
import { GlobalCategory } from '../model/types/CategorySchema';

export const mapGlobalCategories = (
    categories: GlobalCategory[],
): Record<
    string,
    {
        id: string;
        title: string;
    }
> => {
    const parsedData = {};

    categories.forEach((cat) => {
        const catName = cat.catName;
        set(parsedData, cat.uid, {
            id: cat.uid,
            title: startCase(catName.toLowerCase()),
        });
    });

    return parsedData;
};
