import { Image } from 'widgets/Image';
import placeholder from 'shared/assets/images/catalog-item.svg';

interface CatalogItemImageProps {
    src?: string | null;
}

export const CatalogItemImage = ({ src }: CatalogItemImageProps) => {
    return (
        <Image
            src={src}
            style={{
                aspectRatio: '1/1',
                maxHeight: '320px',
                height: '100$',
                width: 'auto',
                margin: '0 auto',
            }}
            placeholder={placeholder}
        />
    );
};
