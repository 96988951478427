import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';

export const applySettingsForAllProperties = createAsyncThunk<
    { message: string },
    { propertyId: string },
    ThunkConfig<string>
>('applySettingsForAllProperties', async ({ propertyId }, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    try {
        const response = await extra.api.post<{ message: string }>(
            `/v1/product_sourcing/settings/${propertyId}/sync-all`,
        );

        return response.data;
    } catch (e) {
        return rejectWithValue('error');
    }
});
