import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, List, ListItem, Typography } from '@mui/material';
import { AccountCircleRounded, Logout } from '@mui/icons-material';
import { getRouteMain, getRouteProfile } from 'shared/const/router';
import { AppLink } from 'shared/ui/AppLink/AppLink';
import { useNavigationBlock } from 'features/NavigationBlockModal/ui/NavigationBlockProvider/NavigationBlockProvider';
import { userActions } from 'entities/User';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { VersionInfo } from 'widgets/VersionInfo';

const listItemStyles = {
    sx: {
        p: 0,
        ':hover': {
            bgcolor: 'action.hover',
            cursor: 'pointer',
        },
    },
};

const listItemContenStyles = {
    width: '100%',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    p: '0 16px',
};

export const AccountMenuItems = () => {
    const { t } = useTranslation();
    const { validateNavBlock } = useNavigationBlock();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const onLogout = () => {
        validateNavBlock(async () => {
            dispatch(userActions.logout());
            navigate(getRouteMain());
        }, true);
    };

    return (
        <List
            sx={{
                padding: 0,
            }}
        >
            <ListItem {...listItemStyles}>
                <Box
                    component={AppLink}
                    to={getRouteProfile()}
                    replace
                    {...listItemContenStyles}
                >
                    <AccountCircleRounded color="primary" />
                    <Typography
                        component="span"
                        display="inline-block"
                        flex="1"
                        fontFamily="OpenSans"
                        fontSize="16px"
                        ml="12px"
                    >
                        {t('Profile')}
                    </Typography>
                </Box>
            </ListItem>

            <ListItem onClick={onLogout} {...listItemStyles}>
                <Box {...listItemContenStyles}>
                    <Logout color="action" />
                    <Typography flex="1" fontSize="16px" ml="12px">
                        {t('Sign Out')}
                    </Typography>
                </Box>
            </ListItem>
            <Typography
                flex="1"
                align="right"
                style={{
                    borderTop: '1px solid #e0e0e0',
                    paddingTop: '8px',
                    paddingRight: '10px',
                    opacity: '0.5',
                    fontSize: '12px',
                }}
                typography="openSans.caption"
            >
                <VersionInfo />
            </Typography>
        </List>
    );
};
