import { Box } from '@mui/material';
import { useEffect, useRef, useState, useCallback } from 'react';
import { ColorChangeHandler, SketchPicker } from 'react-color';
import { useUnmountEffect } from 'shared/lib/hooks/useUnmountEffect';

interface ColorPickerProps {
    onChange: ColorChangeHandler;
    color?: string;
    error?: string;
}

const ColorPicker = ({ color, error, onChange }: ColorPickerProps) => {
    const [value, setValue] = useState(color);
    const [isOpened, setIsOpened] = useState(false);

    const boxRef = useRef<HTMLInputElement>(null);
    const pickerRef = useRef<HTMLInputElement>(null);

    const onChangeValue: ColorChangeHandler = (colorValue, event) => {
        onChange(colorValue, event);
        setValue(colorValue.hex);
    };

    const onColorClick = () => {
        setIsOpened(true);
    };

    const closePicker = useCallback(() => {
        setIsOpened(false);
    }, []);

    const onClose = useCallback(
        (event: Event) => {
            const target = event.target as Node;
            const isClickedOnElements =
                boxRef.current?.contains(target) ||
                pickerRef.current?.contains(target);

            if (!isClickedOnElements) {
                closePicker();
            }
        },
        [closePicker],
    );

    useEffect(() => {
        setValue(color);
    }, [color]);

    useEffect(() => {
        if (isOpened) {
            document.body.addEventListener('click', onClose);
        } else {
            document.body.removeEventListener('click', onClose);
        }
    }, [isOpened, onClose]);

    useUnmountEffect(() => {
        document.body.removeEventListener('click', onClose);
    });

    return (
        <Box sx={{ position: 'relative' }}>
            <Box
                id="ColorPickerBox"
                ref={boxRef}
                onClick={onColorClick}
                width="20px"
                height="20px"
                borderRadius="4px"
                overflow="hidden"
                border="2px solid"
                borderColor={error ? 'error.main' : 'rgba(0, 0, 0, 0.5)'}
                sx={{
                    background:
                        value ||
                        'linear-gradient(90deg, #FF00E5 0%, #FFE500 47.92%, #0FF 100%)',
                }}
            />
            {isOpened && (
                <Box
                    sx={{
                        position: 'absolute',
                        zIndex: 10,
                        transform: 'translate(calc(-100% + 20px), 0px)',
                    }}
                    ref={pickerRef}
                >
                    <SketchPicker
                        presetColors={[]}
                        color={value}
                        onChange={onChangeValue}
                    />
                </Box>
            )}
        </Box>
    );
};

export default ColorPicker;
