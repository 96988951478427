import { Box, List, ListItem, Menu, Typography, alpha } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { useCallback, useState } from 'react';

interface ContextMenuItem {
    label: string;
    onClick: () => void;
}

interface ContextMenuProps {
    list: ContextMenuItem[];
}

export const ContextMenu = ({ list }: ContextMenuProps) => {
    const [menuRef, setmenuRef] = useState<null | HTMLElement>(null);

    const handleOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setmenuRef(event.currentTarget);
    }, []);

    const handleClose = useCallback((event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setmenuRef(null);
    }, []);
    return (
        <>
            <Box height="24px" onClick={handleOpen} sx={{ p: 0 }}>
                <Edit
                    sx={(theme) => ({
                        color: alpha(theme.palette.common?.black, 0.3),
                    })}
                />
            </Box>
            <Menu
                anchorEl={menuRef}
                open={Boolean(menuRef)}
                onClose={handleClose}
                onClick={handleClose}
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.1))',
                            minWidth: '200px',
                            borderRadius: '6px',
                            mt: 1.5,
                        },
                    },
                }}
                sx={{
                    padding: 0,
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <List
                    sx={{
                        padding: 0,
                    }}
                >
                    {list.map((item) => (
                        <ListItem
                            key={item.label}
                            onClick={() => item.onClick()}
                            sx={{
                                p: 0,
                                ':hover': {
                                    bgcolor: 'action.hover',
                                    cursor: 'pointer',
                                },
                            }}
                        >
                            <Box
                                width="100%"
                                height="40px"
                                display="flex"
                                alignItems="center"
                            >
                                <Typography flex="1" fontSize="16px" ml="12px">
                                    {item.label}
                                </Typography>
                            </Box>
                        </ListItem>
                    ))}
                </List>
            </Menu>
        </>
    );
};
