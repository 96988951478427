import { useCallback, useEffect } from 'react';
import { Box } from '@mui/material';
import { fetchUser, userActions } from 'entities/User';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'shared/const/platforms';
import { getOnboardMobile, getRouteProperties } from 'shared/const/router';
import { AuthService } from 'shared/services/authService';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';

export const AuthRedirectPage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const onRedirect = useCallback(async () => {
        // temp
        // latter url wil lbe deffined by role
        const redirectRoute = isMobile()
            ? getOnboardMobile()
            : getRouteProperties();

        try {
            const authService = new AuthService();
            const user = await authService.loginCallback();

            if (user) {
                dispatch(userActions.authenticateUser());
                dispatch(userActions.login(user.access_token));
                await dispatch(fetchUser());

                navigate(redirectRoute);
            }
        } catch (error) {
            navigate(redirectRoute);
        }
    }, [dispatch, navigate]);

    useEffect(() => {
        onRedirect();
    }, [onRedirect]);

    return <Box />;
};
