import { OrderStatuses } from 'entities/Order/model/consts/orderStatuses';
import { NotificationChangeField } from '../model/conts/notificationsConst';
import { Notification } from '../model/types/NotificationsSchema';

export const getDataFromNotification = (notification: Notification) => {
    const status = (notification.changes?.find(
        (change) => change.field === NotificationChangeField.STATUS,
    )?.value || null) as OrderStatuses;

    const triggeredBy =
        notification.changes?.find(
            (change) => change.field === NotificationChangeField.TRIGGEREDBY,
        )?.value || null;

    const { orderNumber, propertyLegalName } = notification.objData;
    const { createdAt, objectId, id, isRead } = notification;

    return {
        id,
        status,
        triggeredBy,
        orderNumber,
        propertyLegalName,
        createdAt,
        objectId,
        isRead,
    };
};
