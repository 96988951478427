import { StateSchema } from 'app/providers/StoreProvider';

export const getRemoveCatalogItemDialog = (state: StateSchema) =>
    state.removeCatalogItem.showDialog;

export const getRemoveCatalogItemIsLoading = (state: StateSchema) =>
    state.removeCatalogItem.isLoading;

export const getRemoveCatalogItemIsSingle = (state: StateSchema) =>
    state.removeCatalogItem.isSingle;
