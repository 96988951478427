import { useTranslation } from 'react-i18next';
import { memo, useCallback, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { makeNotificationsAsReadService } from '../../model/services/makeNotificationsAsReadService';
import { useSelector } from 'react-redux';
import {
    getHasUnreadNotifications,
    getNotificationsUnreadIds,
} from 'entities/Notification/model/selectors/notificationSelectors';
import { NotificationActions } from 'entities/Notification/model/slices/NotificationsSlice';

export const MakeNotificationsReadButton = memo(() => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);

    const unreadIds = useSelector(getNotificationsUnreadIds);
    const hasUnread = useSelector(getHasUnreadNotifications);

    const onClick = useCallback(async () => {
        setLoading(true);

        const response = await dispatch(
            makeNotificationsAsReadService(unreadIds),
        );

        if (response.meta.requestStatus === 'fulfilled') {
            dispatch(NotificationActions.makeAllRead());
        }
        setLoading(false);
    }, [dispatch, unreadIds]);

    return (
        hasUnread && (
            <Button disabled={loading} onClick={onClick} size="small">
                <Typography typography="openSans.subtitle2">
                    {t('Mark All As Read')}
                </Typography>
            </Button>
        )
    );
});
