import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchPropertyById } from '../services/fetchPropertyById/fetchPropertyById';
import { Property } from 'entities/Property/model/types/PropertySchema';
import { FetchPropertyByIdShema } from '../types/FetchPropertyByIdSchema';

const initialState: FetchPropertyByIdShema = {
    isLoading: true,
};

export const FetchPropertyByIdSlice = createSlice({
    name: 'Property',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPropertyById.pending, (state) => {
                state.error = undefined;
                state.isLoading = true;
            })
            .addCase(
                fetchPropertyById.rejected,
                (state, action: PayloadAction<string>) => {
                    state.isLoading = false;
                    state.error = action.payload;
                },
            )
            .addCase(
                fetchPropertyById.fulfilled,
                (state, action: PayloadAction<Property>) => {
                    state.isLoading = false;
                    state.data = action.payload;
                },
            );
    },
});

export const { actions: fetchPropertyByIdSliceActions } =
    FetchPropertyByIdSlice;
export const { reducer: fetchPropertyByIdReducer } = FetchPropertyByIdSlice;
