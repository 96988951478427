import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { OrderTableSchema } from '../../types/OrderTableSchema';

export const fetchOrdersCounters = createAsyncThunk<
    OrderTableSchema['counters'],
    string,
    ThunkConfig<string>
>('order/fetchOrdersCounters', async (propertyId, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    try {
        const response = await extra.api.get<OrderTableSchema['counters']>(
            `/v1/orders/stats`,
            {
                params: { propertyId },
            },
        );

        return response.data;
    } catch (e) {
        return rejectWithValue('error');
    }
});
