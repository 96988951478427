import { ReactNode } from 'react';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import { useSelector } from 'react-redux';
import { getNotificationsIsActive } from 'features/Notifications/model/selectors/notificationsSelectors';

interface ContextPopupProps {
    children: ReactNode;
    anchorEl: null | HTMLElement;
    onClose: () => void;
}

export const ContextPopup = ({
    children,
    anchorEl,
    onClose,
}: ContextPopupProps) => {
    const isActive = useSelector(getNotificationsIsActive);

    return (
        <Menu
            anchorEl={anchorEl}
            open={isActive}
            onClose={onClose}
            TransitionComponent={Fade}
            sx={{ '& .MuiMenu-list': { p: 0 } }}
        >
            {children}
        </Menu>
    );
};
