import { Box } from '@mui/material';
import { Outlet, useParams } from 'react-router-dom';
import { getSelectedPropertyId } from 'entities/Property/model/selectors/PropertySelectors';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';

import { fetchPropertyById } from 'features/fetchPropertyById/model/services/fetchPropertyById/fetchPropertyById';
import { getPropertyItem } from 'features/fetchPropertyById/model/selectors/fetchPropertyByIdSelectors';

export const PropertiesItem = () => {
    const dispatch = useAppDispatch();
    const params = useParams();

    const selectedPropertyId = useSelector(getSelectedPropertyId);
    const propertyItem = useSelector(getPropertyItem);

    const onGetProperty = useCallback(
        async (selectedPropertyId: string) => {
            await dispatch(fetchPropertyById(selectedPropertyId));
        },
        [dispatch],
    );

    useEffect(() => {
        onGetProperty(params?.propertyId);
    }, [onGetProperty, params?.propertyId]);

    return (
        <>
            {selectedPropertyId && propertyItem && (
                <Box display="flex" flexDirection="column" flex={1}>
                    <Outlet />
                </Box>
            )}
        </>
    );
};
