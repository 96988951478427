import { useSelector } from 'react-redux';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { getNavigationIsBlocked } from '../model/selectors/navigationBlockSelectors';
import { useCallback, useRef } from 'react';
import { navigationBlockModalActions } from '../model/slices/NavigationBlockModalSlice';

export const useValidateNavigationBlockState = () => {
    const dispatch = useAppDispatch();
    const navIsBlocked = useSelector(getNavigationIsBlocked);
    const submitCallbackRef = useRef(() => {});
    const dialogWasShown = useRef(false);

    const validateNavBlock = useCallback(
        (onSubmit: () => void, openDialog: boolean) => {
            if (navIsBlocked) {
                submitCallbackRef.current = onSubmit;
                if (openDialog) {
                    dispatch(navigationBlockModalActions.setShowDialog(true));
                    dialogWasShown.current = true;
                }
            } else {
                onSubmit();
            }
        },
        [dispatch, navIsBlocked],
    );

    const proceed = useCallback(() => {
        submitCallbackRef.current();
        submitCallbackRef.current = () => {};
    }, []);

    return { validateNavBlock, proceed };
};
