import { useCallback, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NotificationsButton } from './NotificationsButton';
import { ContextPopup } from 'shared/ui/ContextPopup/ContextPopup';
import { useSelector } from 'react-redux';
import { getNotificationsIsActive } from 'features/Notifications/model/selectors/notificationsSelectors';
import { NotificationsActions } from 'features/Notifications/model/slices/NotificationsSlice';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { useInitialEffect } from 'shared/lib/hooks/useInitialEffect';
import { fetchNotifications } from 'entities/Notification';
import { getUnreadNotificationsCounter } from 'entities/Notification/model/selectors/notificationSelectors';
import { MakeNotificationsReadButton } from 'features/MakeNotificationsRead';
import { NotificationsList } from './NotificationsList';

export const Notifications = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    useInitialEffect(() => {
        dispatch(fetchNotifications());
    }, true);

    const isActive = useSelector(getNotificationsIsActive);
    const newNotificationsCounter = useSelector(getUnreadNotificationsCounter);

    const onPopupOpen = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            dispatch(NotificationsActions.setActive(true));
            setAnchorEl(event.currentTarget);
        },
        [dispatch],
    );

    const onPopupClose = useCallback(() => {
        dispatch(NotificationsActions.setActive(false));
        setAnchorEl(null);
    }, [dispatch]);

    const toggleOpen = (event: React.MouseEvent<HTMLElement>) => {
        if (isActive) {
            onPopupClose();
        } else {
            onPopupOpen(event);
        }
    };

    return (
        <Box
            position="relative"
            alignItems="center"
            color="black"
            sx={{ cursor: 'pointer' }}
        >
            <NotificationsButton onClick={toggleOpen} />
            <ContextPopup anchorEl={anchorEl} onClose={onPopupClose}>
                <Box
                    borderRadius="12px"
                    minWidth="350px"
                    display={'flex'}
                    flexDirection="column"
                    height="100%"
                    overflow="hidden"
                >
                    <Box
                        borderBottom="1px solid"
                        borderColor="divider"
                        mb="12px"
                    >
                        <Typography typography="poppins.subtitle1" p="16px">
                            {t('All Notifications')}
                        </Typography>
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        p="0 6px 0 16px"
                    >
                        {newNotificationsCounter !== 0 && (
                            <Typography typography="poppins.subtitle2">
                                {newNotificationsCounter} {t('New')}
                            </Typography>
                        )}
                        <MakeNotificationsReadButton />
                    </Box>
                    <NotificationsList onClose={onPopupClose} />
                </Box>
            </ContextPopup>
        </Box>
    );
};
