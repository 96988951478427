import { StateSchema } from 'app/providers/StoreProvider';
import { isEmpty } from 'shared/lib/lodash/lodash';

export const getCatalogItems = (state: StateSchema) =>
    state.catalogItem?.dataList;

export const getCatalogItemsTotalCount = (state: StateSchema) =>
    state.catalogItem?.dataList?.length || 0;

export const getSelectedCatalogItems = (state: StateSchema) =>
    state.catalogItem.selectedItems;

export const getSelectedCatalogItem = (state: StateSchema) =>
    state.catalogItem.selectedItem;

export const getCatalogItemsSelectedIsEmpty = (state: StateSchema) =>
    isEmpty(state.catalogItem?.selectedItems);

export const getSelectedCatalogItemsTotal = (state: StateSchema) =>
    Object.values(state.catalogItem?.selectedItems || {}).length;

export const getCatalogItemsAreLoading = (state: StateSchema) =>
    state.catalogItem.listIsLoading;

export const getCatalogItemsShowDetailedView = (state: StateSchema) =>
    state.catalogItem.showDetailedView;
