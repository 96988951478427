import { Image } from 'widgets/Image';
import placeholder from 'shared/assets/images/property.svg';

interface PropertyImageProps {
    src?: string | null;
}

export const PropertyImage = ({ src }: PropertyImageProps) => {
    return (
        <Image
            src={src}
            style={{
                aspectRatio: '380/220',
                maxWidth: '570px',
                height: 'auto',
                width: '100%',
            }}
            placeholder={placeholder}
        />
    );
};
