import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    getCanApproveSelectedOrder,
    getCanMoveToPendingApprovalSelectedOrder,
    getCanMoveToSupervicerReviewSelectedOrder,
    getAdminOrManagerRoles,
} from 'entities/Order/model/selectors/ordersPermissions';
import { getProposalFilterSellersData } from 'features/OrderProposalFilter/model/selectors/orderProposalFilteSelectors';
import {
    ApproveOrderButton,
    MoveToApprovalOrderButton,
    MoveToPendingOrderButton,
} from 'features/changeOrderStatus';
import { getSelectedProperty } from 'entities/Property/model/selectors/PropertySelectors';
import { getSelectedOrder } from 'entities/Order/model/selectors/ordersSelectors';

interface OrderViewPageApproveButtonProps {
    isNoItemsInOrder: boolean;
}

export const OrderViewPageApproveButton = ({
    isNoItemsInOrder,
}: OrderViewPageApproveButtonProps) => {
    const params = useParams();
    const canApprove = useSelector(getCanApproveSelectedOrder);
    const currentProperty = useSelector(getSelectedProperty);
    const currentOrder = useSelector(getSelectedOrder);
    const sellersInProposals = useSelector(getProposalFilterSellersData);
    const canMoveToPendingApproval = useSelector(
        getCanMoveToPendingApprovalSelectedOrder,
    );

    const canMoveToSuperviser = useSelector(
        getCanMoveToSupervicerReviewSelectedOrder,
    );
    const isManagerOrPMC = useSelector(getAdminOrManagerRoles);

    const isRequiredPoInProperty = useMemo(
        () => !!currentProperty?.isPoNumbersRequired,
        [currentProperty],
    );

    const isRequiredPoNumberForOrder = useMemo(() => {
        const poNumbers = currentOrder?.poNumbers;
        let disabledApproveOrder = false;
        Object.entries(sellersInProposals).forEach(([, proposals]) => {
            const sellerUid = proposals?.sellerUid;
            const matchingPo = poNumbers.find((el) => el.id === sellerUid);
            if (!matchingPo?.poNumber) {
                disabledApproveOrder = true;
            }
        });
        return isRequiredPoInProperty && disabledApproveOrder && isManagerOrPMC;
    }, [
        currentOrder,
        isRequiredPoInProperty,
        sellersInProposals,
        isManagerOrPMC,
    ]);

    const orderId = params?.orderId;

    if (canApprove) {
        return (
            <ApproveOrderButton
                orderId={orderId}
                isNoItemsInOrder={isNoItemsInOrder}
                isRequiredPoNumberForOrder={isRequiredPoNumberForOrder}
            />
        );
    }

    if (canMoveToPendingApproval) {
        return (
            <MoveToPendingOrderButton
                orderId={orderId}
                isNoItemsInOrder={isNoItemsInOrder}
            />
        );
    }

    if (canMoveToSuperviser) {
        return (
            <MoveToApprovalOrderButton
                orderId={orderId}
                isNoItemsInOrder={isNoItemsInOrder}
            />
        );
    }

    return null;
};
