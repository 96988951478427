import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RemoveCatalogItemSchema } from '../types/RemoveCatalogItemSchema';
import { removeCatalogItemService } from '../services/removeCatalogItemService';
import { revertCatalogStores } from 'shared/ui/store/resetStoresAction';

const initialState: RemoveCatalogItemSchema = {
    showDialog: false,
    isLoading: false,
    isSingle: false,
};

export const RemoveCatalogItemSlice = createSlice({
    name: 'RemoveCatalogItem',
    initialState,
    reducers: {
        setShowDialog: (state, action: PayloadAction<boolean>) => {
            state.showDialog = action.payload;
        },
        setIsSingle: (state, action: PayloadAction<boolean>) => {
            state.isSingle = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(revertCatalogStores, () => initialState)
            .addCase(removeCatalogItemService.pending, (state) => {
                state.error = undefined;
                state.isLoading = true;
            })
            .addCase(removeCatalogItemService.fulfilled, (state) => {
                state.isLoading = false;
                state.showDialog = false;
            })
            .addCase(removeCatalogItemService.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

export const { actions: removeCatalogItemActions } = RemoveCatalogItemSlice;
export const { reducer: removeCatalogItemReducer } = RemoveCatalogItemSlice;
