import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { Property } from '../../types/PropertySchema';

export const fetchProperties = createAsyncThunk<
    Property[],
    void,
    ThunkConfig<string>
>('property/fetchProperties', async (_, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    try {
        const response = await extra.api.get<Property[]>(`/v1/properties/`);

        return response.data;
    } catch (e) {
        return rejectWithValue('error');
    }
});
