import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { Page } from 'widgets/Page';
import { PageTitle } from 'shared/ui/PageTitle/PageTitle';
import { Outlet, useMatch, useNavigate, useParams } from 'react-router-dom';
import {
    getRouteNotFound,
    getRouteOrdersTableByStatus,
    getRouteOrdersViewItemDetailed,
} from 'shared/const/router';
import { OrderViewPageItems } from './OrderViewPageItems';
import { OrderViewPageInfo } from './OrderViewPageInfo';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { fetchPropertyById } from 'features/fetchPropertyById/model/services/fetchPropertyById/fetchPropertyById';
import { fetchOrder } from 'entities/Order';

import {
    getSelectedOrder,
    getSelectedOrderPropertyId,
    getSelectedOrderStatus,
} from 'entities/Order/model/selectors/ordersSelectors';
import {
    getOrderStatusForRoute,
    useGetOrderStatusTitle,
} from 'entities/Order/model/consts/orderStatuses';

import { propertyActions } from 'entities/Property/model/slices/PropertySlice';
import { orderActions } from 'entities/Order/model/slices/OrderSlice';
import {
    getCatalogItems,
    getSelectedCatalogItems,
} from 'entities/CatalogItem/model/selectors/catalogItemSelectors';
import { fetchCategories } from 'entities/Category';
import { groupBy } from 'shared/lib/lodash/lodash';
import { CatalogItem } from 'entities/CatalogItem/model/types/CatalogItemSchema';
import { getGroupSummary } from 'pages/OrderViewPage/lib/groupSummary/groupSummary';
import { getPropertyCatalogId } from 'features/fetchPropertyById/model/selectors/fetchPropertyByIdSelectors';
import {
    getProposalFilterIsActive,
    getProposalFilteredItems,
} from 'features/OrderProposalFilter/model/selectors/orderProposalFilteSelectors';
import RemoveModal from 'shared/ui/RemoveModal/RemoveModal';
import { deleteOrder } from 'widgets/OrdersTable/model/services/deleteOrder/deleteOrder';
import { useFetchProperties } from 'entities/Property/model/lib/useFetchProperties';

export const OrderViewPage = memo(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const params = useParams();
    const fetchProperties = useFetchProperties();

    const [orderLoaded, setOrderLoaded] = useState(false);

    const catalogItems = useSelector(getCatalogItems);
    const filteredCatalogItems = useSelector(getProposalFilteredItems);
    const proposalFilterIsActive = useSelector(getProposalFilterIsActive);
    const propertyId = useSelector(getSelectedOrderPropertyId);
    const currentOrder = useSelector(getSelectedOrder);
    const currentOrderStatus = useSelector(getSelectedOrderStatus);
    const propertyCatalogId = useSelector(getPropertyCatalogId);
    const selectedItems = useSelector(getSelectedCatalogItems);

    const catalogItemsToDisplay = proposalFilterIsActive
        ? filteredCatalogItems
        : catalogItems;

    const orderId = params?.orderId;

    const isDraftTab = currentOrderStatus === 'DRAFT';
    const orderNumber = currentOrder?.orderNumber || '';
    const [isShowDialog, setIsShowDialog] = useState(false);
    const [isLoadingDeleteOrder, setIsLoadingDeleteOrder] = useState(false);

    const currentOrderStatusTitle = useGetOrderStatusTitle(currentOrderStatus);

    const onBack = useCallback(() => {
        navigate(
            getRouteOrdersTableByStatus(
                propertyId,
                getOrderStatusForRoute(currentOrderStatus),
            ),
        );
    }, [currentOrderStatus, navigate, propertyId]);

    const redirectOnNotFound = useCallback(
        () => navigate(getRouteNotFound()),
        [navigate],
    );

    const initialOrderLoad = useCallback(async () => {
        await fetchProperties(true);
        await dispatch(
            fetchOrder({ id: orderId, onNotFound: redirectOnNotFound }),
        );
        setOrderLoaded(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, orderId, fetchProperties]);

    useEffect(() => {
        initialOrderLoad();
    }, [initialOrderLoad]);

    const initProperty = useCallback(async () => {
        const response = await dispatch(fetchPropertyById(propertyId));

        if (typeof response.payload !== 'string') {
            const catalogId = response.payload.catalogId;
            await dispatch(fetchCategories(catalogId));
        }

        dispatch(propertyActions.setPropertyId(propertyId));
        dispatch(orderActions.setSearchParamsStatus(currentOrderStatus));
    }, [currentOrderStatus, dispatch, propertyId]);

    useEffect(() => {
        if (currentOrder) {
            initProperty();
        }
    }, [currentOrder, initProperty]);

    useEffect(() => {
        if (propertyCatalogId) {
            dispatch(fetchCategories(propertyCatalogId));
        }
    }, [dispatch, propertyCatalogId]);

    const pageTitle = `${t('Order')} ${currentOrder?.orderNumber || ''}`;

    const groupedItems = useMemo(
        () => groupBy(catalogItemsToDisplay, 'categoryId'),
        [catalogItemsToDisplay],
    ) as Record<string, CatalogItem[]>;

    const staticGroupedItems = useMemo(
        () => groupBy(catalogItems, 'categoryId'),
        [catalogItems],
    ) as Record<string, CatalogItem[]>;

    const summaryByCategories = useMemo(
        () => getGroupSummary(staticGroupedItems, selectedItems),
        [staticGroupedItems, selectedItems],
    );

    const totalCost = useMemo(
        () =>
            Object.values(summaryByCategories)
                .reduce((acc, cat) => acc + Number(cat.cost), 0)
                .toFixed(2),
        [summaryByCategories],
    );

    const isDetailedItem = useMatch(
        getRouteOrdersViewItemDetailed(':orderId', ':itemId'),
    );

    const closeDialog = useCallback(() => {
        setIsShowDialog(false);
    }, []);

    const openDialog = useCallback(() => {
        setIsShowDialog(true);
    }, []);

    const deleteOrderFromList = useCallback(async () => {
        setIsLoadingDeleteOrder(true);
        await dispatch(deleteOrder(orderId));
        toast(t('The order has been deleted'));
        setIsLoadingDeleteOrder(false);
        setIsShowDialog(false);
        onBack();
    }, [dispatch, orderId, onBack, t]);

    return (
        <Page bgColor="transparent" padding="0">
            <Box flexDirection="column">
                <Box display="flex" gap="16px">
                    <Box
                        bgcolor="background.paper"
                        borderRadius="16px"
                        p="16px 16px 24px"
                        flex="1"
                    >
                        <Box
                            display="flex"
                            width="100%"
                            justifyContent="space-between"
                        >
                            <Box>
                                <PageTitle
                                    editTitle={pageTitle}
                                    backTitle={t('Back to Orders')}
                                    onBack={onBack}
                                    isEditing
                                    isDraftTab={isDraftTab}
                                    isShowDialog={isShowDialog}
                                    openDialog={openDialog}
                                    isLoading={!orderLoaded}
                                />
                            </Box>
                            {orderLoaded && (
                                <Typography
                                    alignSelf="flex-end"
                                    typography="openSans.subtitle1Medium"
                                    mb="28px"
                                >
                                    {currentOrderStatusTitle}
                                </Typography>
                            )}
                        </Box>

                        {currentOrder && (
                            <OrderViewPageInfo
                                summaryByCategories={summaryByCategories}
                                isLoading={!orderLoaded}
                            />
                        )}
                    </Box>
                    <Box
                        bgcolor="background.paper"
                        borderRadius="16px"
                        flex="1"
                    >
                        {!isDetailedItem && orderLoaded ? (
                            <OrderViewPageItems
                                groupedItems={groupedItems}
                                totalCost={totalCost}
                                onBack={onBack}
                            />
                        ) : (
                            orderLoaded && <Outlet />
                        )}
                    </Box>
                </Box>
            </Box>
            <RemoveModal
                isShow={isShowDialog}
                title={t('Delete the Order')}
                text={`${t(
                    'You are about to delete the Draft Order ID',
                )}: ${orderNumber}`}
                isLoading={isLoadingDeleteOrder}
                onClose={closeDialog}
                onSubmit={deleteOrderFromList}
            />
        </Page>
    );
});
