import { useCallback } from 'react';
import {
    Button,
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    alpha,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getInventoryTableItems } from 'widgets/InventoryTable/model/selectors/catalogItemSelectors';
import { useInitialEffect } from 'shared/lib/hooks/useInitialEffect';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { fetchInventoryItems } from 'widgets/InventoryTable/model/services/fetchCatalogItems/fetchInventoryItems';
import { OrderInventoryTableRow } from './OrderInventoryTableRow';
import { SearchCatalogItems } from 'widgets/SearchCatalogItems';
import {
    PropertyCatalogItemDataDefiner,
    PropertyCatalogItemsMoveTo,
} from 'widgets/PropertyCatalog';
import { getSearchCatalogItemIsActive } from 'features/SearchCatalogItems/model/selectors/searchCatalogItemsSelectors';
import {
    getSelectedPropertyCatalogId,
    getSelectedPropertyId,
} from 'entities/Property/model/selectors/PropertySelectors';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import {
    getCatalogItems,
    getCatalogItemsAreLoading,
} from 'entities/CatalogItem/model/selectors/catalogItemSelectors';
import { CatalogItem } from 'entities/CatalogItem/model/types/CatalogItemSchema';
import { OrderInventoryTableRowSkeleton } from './OrderInventoryTableRowSkeleton';
import { CreateOrderWithItemsButton } from 'features/AddItemsToOrder';
import { CatalogItemsSelectedCounter } from 'entities/CatalogItem';
import { useNavaigateFromOrder } from 'shared/lib/hooks/useNavaigateFromOrder';
import { getUserCanSeePrices } from 'entities/User/model/selectors/userPermissionsSeletors';
import { catalogItemActions } from 'entities/CatalogItem/model/slices/CatalogItemSlice';
import {
    getCatalogItemsShowDetailedView,
    getSelectedCatalogItem,
} from 'entities/CatalogItem/model/selectors/catalogItemSelectors';
import { getRouteOrderCatalogInventoryItem } from 'shared/const/router';
import { getSelectedOrderId } from 'entities/Order/model/selectors/ordersSelectors';

export const OrderInventoryTable = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const data = useSelector(getInventoryTableItems);
    const { onNavigateBack } = useNavaigateFromOrder();

    const currentPageIsOrders = useMatch('/orders' + '/*');
    const selectedOrderId = useSelector(getSelectedOrderId);
    const selectedPropertyId = useSelector(getSelectedPropertyId);
    const selectedItem = useSelector(getSelectedCatalogItem);
    const detailedViewIsShown = useSelector(getCatalogItemsShowDetailedView);
    const isActiveSearch = useSelector(getSearchCatalogItemIsActive);
    const isLoading = useSelector(getCatalogItemsAreLoading);
    const catalogItems = useSelector(getCatalogItems) as CatalogItem[];
    const selectedPropertyCatalogId = useSelector(getSelectedPropertyCatalogId);
    const canSeePrices = useSelector(getUserCanSeePrices);

    const dataToDisplay = isActiveSearch ? catalogItems : data;

    const catalogId =
        location.state?.selectedPropertyCatalogId || selectedPropertyCatalogId;

    useInitialEffect(async () => {
        await dispatch(fetchInventoryItems(catalogId));
    });

    const onExpandRow = useCallback(
        (item: CatalogItem) => () => {
            dispatch(catalogItemActions.setSelectedItem(item));
            dispatch(catalogItemActions.setShowDetailedView(true));
            navigate(getRouteOrderCatalogInventoryItem(item.id), {
                state: {
                    selectedOrderId,
                    selectedPropertyCatalogId,
                    selectedPropertyId,
                    fromOrder: selectedOrderId,
                },
            });
        },
        [
            dispatch,
            navigate,
            selectedOrderId,
            selectedPropertyCatalogId,
            selectedPropertyId,
        ],
    );

    return (
        <Box>
            {detailedViewIsShown && selectedItem ? (
                <Box
                    mt="10px"
                    p="16px"
                    borderRadius="8px"
                    border="1px solid"
                    borderColor="divider"
                >
                    <PropertyCatalogItemDataDefiner
                        isDetailed
                        item={selectedItem}
                        isOrderPage={!!currentPageIsOrders}
                    />
                </Box>
            ) : (
                <>
                    <SearchCatalogItems
                        catalogId={catalogId}
                        moveTo={PropertyCatalogItemsMoveTo.ORDER}
                    />
                    <Box
                        mt="8px"
                        padding="8px 16px"
                        bgcolor={(theme) => alpha(theme.palette.grey[500], 0.3)}
                        height={'calc(100vh - 466px)'}
                        sx={{ overflowY: 'scroll', overflowX: 'hidden' }}
                    >
                        <Box
                            height={'calc(100vh - 482px)'}
                            sx={{ overflowY: 'scroll' }}
                            data-testid="Order-inventory-table"
                        >
                            <Table
                                sx={{
                                    '& .MuiTableCell-root': {
                                        borderBottom: 'none',
                                    },
                                    borderCollapse: 'separate',
                                    borderSpacing: '0 8px',
                                }}
                            >
                                <TableHead
                                    sx={{
                                        '& th': {
                                            paddingTop: '0px',
                                            paddingBottom: '0px',
                                        },
                                    }}
                                >
                                    <TableRow>
                                        <TableCell
                                            padding="checkbox"
                                            width="38px"
                                        ></TableCell>
                                        <TableCell>
                                            <Typography typography="openSans.captionMedium">
                                                {t('Item')}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography typography="openSans.captionMedium">
                                                {t('SKU')}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography typography="openSans.captionMedium">
                                                {t('Qty/UOM')}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography typography="openSans.captionMedium">
                                                {t('UOM')}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography typography="openSans.captionMedium">
                                                {t('Min/Hand')}
                                            </Typography>
                                        </TableCell>
                                        {canSeePrices && (
                                            <TableCell align="right">
                                                <Typography typography="openSans.captionMedium">
                                                    {t('Price/UOM')}
                                                </Typography>
                                            </TableCell>
                                        )}
                                        <TableCell
                                            padding="checkbox"
                                            width="20px"
                                        ></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!isLoading &&
                                        dataToDisplay?.map((row) => (
                                            <OrderInventoryTableRow
                                                key={row.id}
                                                item={row}
                                                canSeePrices={canSeePrices}
                                                onExpandRow={onExpandRow(row)}
                                            />
                                        ))}

                                    {isLoading &&
                                        Array.from({ length: 5 }).map(
                                            (_, index) => (
                                                <OrderInventoryTableRowSkeleton
                                                    key={index}
                                                />
                                            ),
                                        )}
                                </TableBody>
                            </Table>
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        alignItems="flex-end"
                        flexDirection="column"
                        borderRadius="0 0 12px 12px"
                        border="1px solid"
                        borderColor="divider"
                        p="0 16px 16px 0"
                    >
                        <CatalogItemsSelectedCounter />
                        <Box display="flex" gap="8px">
                            <Button onClick={onNavigateBack}>
                                {t('Cancel')}
                            </Button>
                            <CreateOrderWithItemsButton />
                        </Box>
                    </Box>
                </>
            )}
        </Box>
    );
};
