import { Box, Typography } from '@mui/material';
import { Form, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import FormikInput from 'shared/ui/Formik/FormikInput/FormikInput';
import { FormikColorPicker } from 'shared/ui/ColorPicker';

export interface CategoryItemFormValues {
    color: string;
    title: string;
    glCode: string;
}

interface CatalogItemInputProps {
    formTitle: string;
    loading?: boolean;
    formikProps: FormikProps<CategoryItemFormValues>;
}

export const CatalogItemInput = ({
    formTitle,
    loading,
    formikProps,
}: CatalogItemInputProps) => {
    const { t } = useTranslation();

    const colorError = () => {
        if (formikProps.errors.title) return null;
        if (formikProps.errors.color && formikProps.touched.color)
            return formikProps.errors.color;
    };

    return (
        <Form>
            <Typography
                data-testid="Popup-title"
                mb="24px"
                typography="poppins.subtitle2"
            >
                {formTitle}
            </Typography>
            <Box display="flex" flexDirection="column" gap="20px">
                <FormikInput
                    name="title"
                    label={t('Category Name')}
                    loading={loading}
                    minimized
                />
                <Box display="flex" gap="8px">
                    <Box flexBasis="50%">
                        <FormikInput
                            name="glCode"
                            label={t('GL Code (optional)')}
                            loading={loading}
                            minimized
                        />
                    </Box>

                    <Box display="flex" gap="16px">
                        <Box position="relative" flex="1 1">
                            <FormikInput
                                name="color"
                                hasError={Boolean(formikProps.errors?.color)}
                                label={t('Color')}
                                loading={loading}
                                minimized
                                disabled
                                onBlurAction={() =>
                                    formikProps.setFieldTouched('color')
                                }
                            />
                            <Box
                                position="absolute"
                                zIndex="1"
                                top="10px"
                                right="8px"
                            >
                                <FormikColorPicker name="color" />
                            </Box>
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    color: 'error.main',
                                    position: 'absolute',
                                    bottom: '0',
                                    transform: 'translate(0%, 100%)',
                                    ml: '0',
                                }}
                                position="absolute"
                            >
                                {colorError()}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Form>
    );
};
