import { memo } from 'react';
import { Box } from '@mui/material';
import { isMobile } from 'shared/const/platforms';
import { Navigate } from 'react-router-dom';
import { getRouteMain } from 'shared/const/router';
import { getUrlMobileApp } from 'shared/const/mobileRoutes';
import { USER_ACCESS_TOKEN } from 'shared/const/localstorage';
import { useSelector } from 'react-redux';
import { getUserIsAuthed } from 'entities/User';

export const OnboardMobileUserPage = memo(() => {
    const auth = useSelector(getUserIsAuthed);

    if (!isMobile() || !auth) {
        return <Navigate to={getRouteMain()} replace />;
    } else {
        window.location.replace(
            getUrlMobileApp(localStorage.getItem(USER_ACCESS_TOKEN)),
        );
    }

    return (
        <Box display="flex" alignItems="center" justifyContent="center"></Box>
    );
});
