import { memo } from 'react';
import { Box, Typography } from '@mui/material';

interface CatalogItemRawProps {
    label: string;
    value: string | number;
}

export const CatalogItemRaw = memo(({ label, value }: CatalogItemRawProps) => {
    return (
        <Box
            data-testid={`Item-${label}`}
            display="flex"
            justifyContent="space-between"
        >
            <Typography typography="openSans.body2">{label}</Typography>
            <Typography typography="openSans.subtitle2" textAlign="right">
                {value}
            </Typography>
        </Box>
    );
});
