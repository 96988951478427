import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { SupplierAccountInfoResponseSchema } from '../types/SupplierSchema';

export const fetchSupplierAccountManagerInfo = createAsyncThunk<
    SupplierAccountInfoResponseSchema,
    { supplierId: string; propertyId: string },
    ThunkConfig<string>
>('supplier/account-info', async ({ supplierId, propertyId }, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    try {
        const response = await extra.api.get<SupplierAccountInfoResponseSchema>(
            `/v1/properties/${propertyId}/vendors/${supplierId}`,
        );

        return response.data;
    } catch (e) {
        return rejectWithValue('error');
    }
});
