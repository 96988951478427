import {
    Accordion,
    AccordionSummary,
    Box,
    Radio,
    Typography,
    alpha,
} from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { memo, useCallback, useState } from 'react';
import { useField } from 'formik';
import { CategoryContextMenu } from 'features/CategoryContextMenu';
import { TruncatedText } from 'shared/ui/TruncatedText/TruncatedText';
import { CategoryForm } from 'features/CategoryForm';
import { CategoryFormMode } from 'features/CategoryForm/module/types/categoryFormsTypes';
import { useTranslation } from 'react-i18next';

interface AddCatalogItemCategoryProps {
    title: string;
    id: string;
    glCode: string;
    catalogId: string;
    color: string;
    subGroups?: any;
}

export const AddCatalogItemCategory = memo(
    ({
        title,
        glCode,
        id,
        subGroups,
        color,
        catalogId,
    }: AddCatalogItemCategoryProps) => {
        const { t } = useTranslation();
        const [{ value }, , { setValue }] = useField('category');
        const [editMode, setEditMode] = useState<boolean>(false);

        const onSetValue = () => {
            setValue(id);
        };

        const onOpen = useCallback(() => {
            setEditMode(true);
        }, []);

        const onClose = useCallback(() => {
            setEditMode(false);
        }, []);

        return (
            <Box data-testid="Category">
                {!editMode ? (
                    <Accordion sx={{ overflow: 'unset' }}>
                        <AccordionSummary
                            sx={{ pl: '8px' }}
                            expandIcon={
                                subGroups ? (
                                    <ArrowDropDown
                                        sx={(theme) => ({
                                            color: alpha(
                                                theme.palette.common?.black,
                                                0.3,
                                            ),
                                        })}
                                    />
                                ) : undefined
                            }
                        >
                            <Box
                                display="flex"
                                alignItems="center"
                                flex="1"
                                gap="4px"
                                onClick={onSetValue}
                            >
                                <Radio
                                    data-testid="Category-radio-button"
                                    value={value}
                                    checked={value === id}
                                    name="category"
                                    sx={{
                                        p: 0,
                                        width: '24px',
                                        height: '24px',
                                        display: 'block',
                                    }}
                                />
                                <Box
                                    height="8px"
                                    width="8px"
                                    bgcolor={color}
                                    flexBasis="10px"
                                    borderRadius="50%"
                                />
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    ml="8px"
                                    width="100%"
                                >
                                    <TruncatedText width="190px">
                                        {title}
                                    </TruncatedText>
                                    <Typography
                                        color={(theme) =>
                                            alpha(
                                                theme.palette.common?.black,
                                                0.5,
                                            )
                                        }
                                    >
                                        {glCode ? ` | ${glCode}` : ''}
                                    </Typography>
                                </Box>
                                <CategoryContextMenu onEdit={onOpen} />
                            </Box>
                        </AccordionSummary>
                    </Accordion>
                ) : (
                    <CategoryForm
                        onClose={onClose}
                        mode={CategoryFormMode.EDIT}
                        catalogId={catalogId}
                        glCode={glCode}
                        categoryId={id}
                        formTitle={t('Edit Category')}
                        title={title}
                        color={color}
                    />
                )}
            </Box>
        );
    },
);
