import * as React from 'react';
import { Typography, Button } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

interface BackButtonProps {
    title: string;
    marginBottom?: string;
    onBack: () => void;
}

export const BackButton = ({
    onBack,
    title,
    marginBottom = '16px',
}: BackButtonProps) => {
    return (
        <Button
            onClick={onBack}
            startIcon={<ArrowBack sx={{ width: '24px', height: '24px' }} />}
            sx={{
                justifyContent: 'flex-start',
                color: 'rgba(0,0,0,0.5)',
                p: 0,
                mb: marginBottom,
                ':hover': {
                    bgcolor: 'transparent',
                },
                '.MuiButton-startIcon': {
                    ml: 0,
                },
            }}
        >
            <Typography typography="openSans.body2">{title}</Typography>
        </Button>
    );
};
