import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { InventoryTableSchema } from '../types/InventoryTableSchema';
import { has, set, unset } from 'shared/lib/lodash/lodash';
import { fetchInventoryItems } from '../services/fetchCatalogItems/fetchInventoryItems';

const initialState: InventoryTableSchema = {
    isLoading: false,
};

export const InventoryTableSlice = createSlice({
    name: 'InventoryTable',
    initialState,
    reducers: {
        selectItem: (
            state,
            action: PayloadAction<{ id: string; uid?: string }>,
        ) => {
            const newData = { ...state.selectedItems };
            const { id, uid } = action.payload;

            if (!has(newData, id)) {
                set(newData, id, { uid });
                state.selectedItems = newData;
            }
        },
        deselectItem: (state, action: PayloadAction<string>) => {
            const newData = { ...state.selectedItems };
            const id = action.payload;

            if (has(newData, id)) {
                unset(newData, id);
                state.selectedItems = newData;
            }
        },
        clearSelection: (state) => {
            state.selectedItems = undefined;
        },
        clearItems: (state) => {
            state.items = undefined;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchInventoryItems.pending, (state) => {
                state.error = undefined;
                state.isLoading = true;
                state.items = undefined;
            })
            .addCase(fetchInventoryItems.fulfilled, (state, action) => {
                state.isLoading = false;
                state.items = action.payload;
            })
            .addCase(fetchInventoryItems.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
                state.items = undefined;
            });
    },
});

export const { actions: inventoryTableActions } = InventoryTableSlice;
export const { reducer: inventoryTableReducer } = InventoryTableSlice;
