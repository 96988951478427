import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { CatalogItem } from 'entities/CatalogItem/model/types/CatalogItemSchema';
import { useTranslation } from 'react-i18next';
import { OrderGroupedItemsTableRow } from './OrderGroupedItemsTableRow';
import { OrderStatuses } from 'entities/Order/model/consts/orderStatuses';
import { useSelector } from 'react-redux';
import { getUserCanSeePrices } from 'entities/User/model/selectors/userPermissionsSeletors';
import { OrderInventoryTableRowSkeleton } from 'widgets/InventoryTable/ui';
import { getSelectedOrderIsLoading } from 'entities/Order/model/selectors/ordersSelectors';
import { UserRole, getUserRole } from 'entities/User';

interface OrderGroupedItemsTableProps {
    items: CatalogItem[];
    orderStatus: OrderStatuses;
}

export const OrderGroupedItemsTable = ({
    items,
    orderStatus,
}: OrderGroupedItemsTableProps) => {
    const userRole = useSelector(getUserRole);
    const canSeePrices = useSelector(getUserCanSeePrices);
    const isLoading = useSelector(getSelectedOrderIsLoading);

    const isInReview = orderStatus === OrderStatuses.IN_REVIEW;
    const isHidePrice = userRole === UserRole.MAINTENANCE_TECHNICIAN;

    const { t } = useTranslation();
    return !isLoading ? (
        <TableContainer>
            <Table
                sx={{
                    '& .MuiTableHead-root .MuiTableCell-root': {
                        borderBottom: 'none',
                    },
                    '& .MuiTableBody-root .MuiTableRow-root:nth-child(3n+1) .MuiTableCell-root':
                        {
                            '&:first-child': {
                                borderTop: '1px solid',
                                borderLeft: '1px solid',
                                borderColor: 'grey.500',
                                borderRadius: '4px 0px 0px 4px',
                            },
                            '&:last-child': {
                                borderTop: '1px solid',
                                borderRight: '1px solid',
                                borderRadius: '0px 4px 4px 0px',
                                borderColor: 'grey.500',
                            },
                            '&': {
                                borderTop: '1px solid',
                                borderBottom: 'none',
                                borderRadius: '0px 4px 4px 0px',
                                borderColor: 'grey.500',
                                p: '12px',
                            },
                        },
                    '& .MuiTableBody-root .MuiTableRow-root:nth-child(3n+2) .MuiTableCell-root':
                        {
                            '&:first-child': {
                                borderBottom: '1px solid',
                                borderLeft: '1px solid',
                                borderColor: 'grey.500',
                                borderRadius: '4px 0px 0px 4px',
                            },
                            '&:last-child': {
                                borderBottom: '1px solid',
                                borderRight: '1px solid',
                                borderRadius: '0px 4px 4px 0px',
                                borderColor: 'grey.500',
                            },
                            '&': {
                                borderBottom: '1px solid',
                                borderRadius: '0px 4px 4px 0px',
                                borderColor: 'grey.500',
                                p: '12px',
                            },
                        },
                    '& .MuiTableBody-root .MuiTableRow-root:nth-child(3n+3) .MuiTableCell-root':
                        {
                            p: '7px',
                        },
                }}
            >
                <TableHead
                    sx={{
                        '& th': {
                            paddingTop: '0px',
                            paddingBottom: '4px',
                        },
                    }}
                >
                    <TableRow>
                        <TableCell>
                            <Typography typography="openSans.captionMedium">
                                {t('Item')}
                            </Typography>
                        </TableCell>
                        {!isInReview ? (
                            <>
                                <TableCell>
                                    <Typography
                                        typography="openSans.captionMedium"
                                        align="center"
                                    >
                                        {t('UOM')}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        typography="openSans.captionMedium"
                                        align="center"
                                    >
                                        {t('Qty')}
                                    </Typography>
                                </TableCell>
                                {canSeePrices && !isHidePrice && (
                                    <TableCell>
                                        <Typography
                                            typography="openSans.captionMedium"
                                            align="right"
                                        >
                                            {t('Total')}
                                        </Typography>
                                    </TableCell>
                                )}
                            </>
                        ) : (
                            <>
                                <TableCell>
                                    <Typography
                                        typography="openSans.captionMedium"
                                        align="center"
                                    >
                                        {t('UOM')}
                                    </Typography>
                                </TableCell>
                                <TableCell width="140px">
                                    <Typography
                                        typography="openSans.captionMedium"
                                        align="center"
                                    >
                                        {t('Qty')}
                                    </Typography>
                                </TableCell>
                                {canSeePrices && !isHidePrice && (
                                    <TableCell>
                                        <Typography
                                            typography="openSans.captionMedium"
                                            align="right"
                                        >
                                            {t('Total')}
                                        </Typography>
                                    </TableCell>
                                )}
                            </>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item) => (
                        <OrderGroupedItemsTableRow key={item.id} item={item} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    ) : (
        <>
            <OrderInventoryTableRowSkeleton />
            <OrderInventoryTableRowSkeleton />
            <OrderInventoryTableRowSkeleton />
        </>
    );
};
