import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProfileSchema } from '../types/ProfileCardSchema';
import { fetchUser, updateUser, UserProfile } from 'entities/User';
import { toast } from 'react-toastify';

const initialState: ProfileSchema = {
    readonly: true,
    isLoading: false,
    isInitialLoading: false,
    error: undefined,
    data: undefined,
};

export const ProfileCardSlice = createSlice({
    name: 'ProfileCard',
    initialState,
    reducers: {
        setReadonly: (state, action: PayloadAction<boolean>) => {
            state.readonly = action.payload;
        },
        cancelEdit: (state) => {
            state.readonly = true;
        },
        updateProfile: (state, action: PayloadAction<UserProfile>) => {
            state.data = {
                ...state.data,
                ...action.payload,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUser.pending, (state) => {
                state.error = undefined;
                state.isInitialLoading = true;
            })
            .addCase(
                fetchUser.fulfilled,
                (state, action: PayloadAction<UserProfile>) => {
                    state.isInitialLoading = false;
                    state.data = action.payload;
                },
            )
            .addCase(fetchUser.rejected, (state, action) => {
                state.isInitialLoading = false;
                state.error = action.payload;
            })
            .addCase(updateUser.pending, (state) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(
                updateUser.fulfilled,
                (state, action: PayloadAction<UserProfile>) => {
                    state.isLoading = false;
                    state.data = action.payload;
                    state.readonly = true;
                    toast('Profile saved');
                },
            )
            .addCase(updateUser.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

export const { actions: profileActions } = ProfileCardSlice;
export const { reducer: profileReducer } = ProfileCardSlice;
