import { useRef } from 'react';
import { useDebouncedCallback } from 'shared/lib/hooks/useDebouncedCallback';
import * as Sentry from '@sentry/react';

export const useFetchWIthController = () => {
    const abortRequestControllerRef = useRef(null);

    const fetchWithController = useDebouncedCallback(
        async (
            fetchWithController: (controller: AbortController) => Promise<any>,
        ) => {
            if (abortRequestControllerRef.current) {
                abortRequestControllerRef.current.abort();
                abortRequestControllerRef.current = null;
            }

            const abortController = new AbortController();
            abortRequestControllerRef.current = abortController;

            try {
                const response = await fetchWithController(abortController);

                if (response?.meta?.requestStatus === 'fulfilled') {
                    abortRequestControllerRef.current = null;
                }

                return response;
            } catch (error) {
                Sentry.captureException(error);
            }
        },
    );
    return fetchWithController;
};
