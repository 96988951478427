import { Box, Button, List, ListItem, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { DialogBoxContent } from 'shared/ui/DialogBoxContent/DialogBoxContent';

interface AddCatalogItemDialogErrorProps {
    duplicates: string[];
    onClose: () => void;
}

export const AddCatalogItemDialogError = ({
    duplicates,
    onClose,
}: AddCatalogItemDialogErrorProps) => {
    const { t } = useTranslation();

    return (
        <DialogBoxContent
            onClose={onClose}
            buttons={
                <Box display="flex" justifyContent="flex-end" width="100%">
                    <Button onClick={onClose}>{t('OK')}</Button>
                </Box>
            }
        >
            <Box p="0 24px" maxWidth="360px">
                <Typography typography={'poppins.subtitle2'} mb="21px">
                    {t('Duplicate Item')}
                    {t('(s)')}
                </Typography>
                <Typography typography={'openSans.body2'} mb="21px">
                    {t(
                        'The Property Catalog already includes the following item',
                    )}{' '}
                    {t('sku')}
                    {t('(s)')},{' '}
                    {t('so no action has been taken for the below item')}
                    {t('(s)')}.
                </Typography>
                <List sx={{ p: '0 ', mb: '36px' }}>
                    {duplicates?.map((duplicate) => (
                        <ListItem sx={{ p: '0 0 10px 0' }} key={duplicate}>
                            <Typography typography={'poppins.subtitle2'}>
                                {duplicate}
                            </Typography>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </DialogBoxContent>
    );
};
