import { Box, LinearProgress } from '@mui/material';
import { theme } from 'app/providers/ThemeProvider/config/theme';

export const MobileOverlay = () => {
    return (
        <Box
            bgcolor={theme.palette.background.default}
            zIndex={10000}
            position={'absolute'}
            top={0}
            left={0}
            right={0}
            bottom={0}
        >
            <LinearProgress color="primary" />
        </Box>
    );
};
