import { useTranslation } from 'react-i18next';
import { memo } from 'react';
import { Typography } from '@mui/material';
import { DriveFileMoveRounded } from '@mui/icons-material';
import { WithMoveToInventoryCatalogItem } from 'features/moveToInventoryCatalogItem';

interface MoveToInventoryCatalogItemButtonProps {
    isSingleItem?: boolean;
}

export const MoveToInventoryCatalogItemButton = memo(
    ({ isSingleItem }: MoveToInventoryCatalogItemButtonProps) => {
        const { t } = useTranslation();

        return (
            <WithMoveToInventoryCatalogItem isSingle={isSingleItem}>
                <DriveFileMoveRounded color="primary" />
                <Typography
                    color="primary"
                    typography="openSans.subtitle2"
                    ml="4px"
                >
                    {t('Add to Inventory')}
                </Typography>
            </WithMoveToInventoryCatalogItem>
        );
    },
);
