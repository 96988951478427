import { Box } from '@mui/material';
import { CatalogItemSkeleton } from 'entities/CatalogItem';

import { PropertyCatalogItemsMoveTo } from 'widgets/PropertyCatalog';
import {
    CatalogItem,
    GlobalCatalogItem,
} from 'entities/CatalogItem/model/types/CatalogItemSchema';
import { PropertyCatalogItemDataDefiner } from './PropertyCatalogItemDataDefiner';

interface PropertyCatalogItemsListProps {
    items: CatalogItem[] | GlobalCatalogItem[];
    loading: boolean;
    quickEdit?: boolean;
    isOrderPage?: boolean;
    moveTo: PropertyCatalogItemsMoveTo;
    isSearch?: boolean;
}

export const PropertyCatalogItemsList = ({
    loading,
    items,
    moveTo,
    isOrderPage,
    quickEdit,
    isSearch,
}: PropertyCatalogItemsListProps) => {
    const hasItems = items && items?.length;

    return (
        <Box data-testid="Items-list" width="100%">
            {!loading ? (
                hasItems ? (
                    items.map((item) => {
                        const id = 'uid' in item ? item.uid : item.id;
                        return (
                            <PropertyCatalogItemDataDefiner
                                key={id}
                                item={item}
                                moveTo={moveTo}
                                quickEdit={quickEdit}
                                isOrderPage={isOrderPage}
                                isSearch={isSearch}
                            />
                        );
                    })
                ) : null
            ) : (
                <>
                    <CatalogItemSkeleton />
                    <CatalogItemSkeleton />
                    <CatalogItemSkeleton />
                </>
            )}
        </Box>
    );
};
