import { Link, LinkProps } from 'react-router-dom';
import { memo, ReactNode } from 'react';

interface AppLinkProps extends LinkProps {
    color?: string;
    children?: ReactNode;
}

export const AppLink = memo((props: AppLinkProps) => {
    const { to, children, color, ...otherProps } = props;

    return (
        <Link
            style={{ textDecoration: 'none', color: color || 'black' }}
            to={to}
            {...otherProps}
        >
            {children}
        </Link>
    );
});
