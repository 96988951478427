import { User, UserManager } from 'oidc-client-ts';
import authConfig from 'shared/config/oidcClient/oidcClient';

export class AuthService {
    userManager: UserManager;

    constructor() {
        this.userManager = new UserManager(authConfig);
    }

    getUser(): Promise<User | null> {
        return this.userManager.getUser();
    }

    login(): Promise<void> {
        return this.userManager.signinRedirect({ prompt: 'select_account' });
    }

    loginCallback(): Promise<User | null> {
        return this.userManager.signinRedirectCallback();
    }

    logout(): Promise<void> {
        return this.userManager.removeUser();
    }
}
