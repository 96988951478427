import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { GlobalCatalogItem } from '../../types/CatalogItemSchema';
import { ResponseWithPagination } from 'shared/types/responses';

interface GlobalCatalogItemsRequestData {
    searchString?: string;
    categoryId?: string;
    abortController?: AbortController;
}

export const fetchGlobalCatalogItems = createAsyncThunk<
    ResponseWithPagination<GlobalCatalogItem[]>,
    GlobalCatalogItemsRequestData,
    ThunkConfig<string>
>(
    'catalogItem/fetchGlobalCatalogItem',
    async ({ searchString, categoryId, abortController }, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi;

        try {
            const response = await extra.api.get<
                ResponseWithPagination<GlobalCatalogItem[]>
            >(`/v1/catalogs/search-items`, {
                signal: abortController?.signal,
                params: { searchString, categoryId },
            });

            return response.data;
        } catch (e) {
            return rejectWithValue('error');
        }
    },
);
