import { Box, Button, Dialog, Typography } from '@mui/material';
import {
    getSelectedCatalogItem,
    getSelectedCatalogItems,
} from 'entities/CatalogItem/model/selectors/catalogItemSelectors';
import { getSelectedPropertyCatalogId } from 'entities/Property/model/selectors/PropertySelectors';
import {
    getRemoveCatalogItemDialog,
    getRemoveCatalogItemIsLoading,
    getRemoveCatalogItemIsSingle,
} from 'features/removeCatalogItem/model/selectors/removeCatalogItemSelectors';
import { removeCatalogItemService } from 'features/removeCatalogItem/model/services/removeCatalogItemService';
import { removeCatalogItemActions } from 'features/removeCatalogItem/model/slices/RemoveCatalogItemSlice';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { DialogBoxContent } from 'shared/ui/DialogBoxContent/DialogBoxContent';
import { Loader } from 'shared/ui/Loader/Loader';

export const RemoveCatalogItemDialog = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isOpened = useSelector(getRemoveCatalogItemDialog);
    const isLoading = useSelector(getRemoveCatalogItemIsLoading);
    const selectedItems = useSelector(getSelectedCatalogItems);
    const selectedItem = useSelector(getSelectedCatalogItem);
    const selectedCatalogId = useSelector(getSelectedPropertyCatalogId);
    const isSingleDelete = useSelector(getRemoveCatalogItemIsSingle);

    const onClose = () => {
        dispatch(removeCatalogItemActions.setShowDialog(false));
    };

    const onSubmit = async () => {
        const idOfSelectedItem = (selectedItem as any)?.uuid;
        const data = {
            items:
                isSingleDelete && idOfSelectedItem
                    ? [
                          {
                              itemUuid: idOfSelectedItem,
                          },
                      ]
                    : Object.values(selectedItems).map((item) => ({
                          itemUuid: item.uid,
                      })),
        };
        await dispatch(
            removeCatalogItemService({
                catalogId: selectedCatalogId,
                data,
                isSingle: Boolean(isSingleDelete),
            }),
        );
    };

    return (
        isOpened && (
            <Dialog open onClose={onClose}>
                <DialogBoxContent
                    onClose={onClose}
                    buttons={
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            width="100%"
                        >
                            <Button onClick={onClose}>{t('Cancel')}</Button>
                            <Button
                                disabled={isLoading}
                                startIcon={
                                    isLoading && <Loader color="inherit" />
                                }
                                onClick={onSubmit}
                                variant="contained"
                                color="error"
                            >
                                {t('Remove')}
                            </Button>
                        </Box>
                    }
                >
                    <Box p="0 24px">
                        <Typography typography={'poppins.subtitle2'} mb="18px">
                            {isSingleDelete
                                ? t('Remove Selected Item from Catalog')
                                : t('Remove Selected Items from Catalog')}
                        </Typography>
                        <Typography typography={'openSans.body2'} mb="18px">
                            {isSingleDelete
                                ? t(
                                      'Do you want to remove selected item from catalog',
                                  )
                                : t(
                                      'Do you want to remove selected items from catalog',
                                  )}
                            ?
                        </Typography>
                    </Box>
                </DialogBoxContent>
            </Dialog>
        )
    );
};
