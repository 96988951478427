import { isJestRuntime, isDev } from 'shared/const/envVariables';
import * as Sentry from '@sentry/react';
import packageData from './vi.json';

if (!isDev && !isJestRuntime) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_SENTRY_ENV,
        release: packageData.version,
        integrations: [new Sentry.BrowserTracing()],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    });
}
