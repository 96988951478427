import { Box, Fade, Typography } from '@mui/material';
import { Link } from '@mui/icons-material';
import { CustomWidthTooltip } from 'shared/ui/Tooltip';

export const ColoredLinkBox = ({
    color,
    tooltipText,
}: {
    color: string;
    tooltipText: string;
}) => (
    <CustomWidthTooltip
        arrow
        placement="right"
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 200 }}
        title={
            <Typography typography="openSans.caption">{tooltipText}</Typography>
        }
    >
        <Box
            sx={{
                width: '24px',
                height: '24px',
                borderRadius: '2px',
                padding: '4px',
                bgcolor: color,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Link sx={{ fontSize: '18px' }} />
        </Box>
    </CustomWidthTooltip>
);
