import { StateSchema } from 'app/providers/StoreProvider';
import { acceptableRolesForApp } from '../consts/userConsts';

export const getUserData = (state: StateSchema) => state.user.userData;
export const getUserIsAuthed = (state: StateSchema) => state.user.isAuthed;
export const getUserInited = (state: StateSchema) => state.user._inited;
export const getUserRole = (state: StateSchema) => state.user.userData?.groups;
export const getUserHasPMCId = (state: StateSchema) =>
    Boolean(state.user.userData?.pmcId);

export const getProfileIsLoading = (state: StateSchema) =>
    state.user.isProfileLoading;

export const getAuthIsLoading = (state: StateSchema) =>
    state.user.isAuthLoading;

export const getUserRoleIsAcceptable = (state: StateSchema) =>
    acceptableRolesForApp.includes(state.user.userData?.groups);
