import { GlobalCategory } from 'entities/Category/model/types/CategorySchema';
import { $api } from 'shared/api/api';

export const fetchSubCategories = async (parentId: string) => {
    try {
        const response = await $api.get<GlobalCategory[]>(
            `/v1/catalogs/global-categories`,
            {
                params: {
                    parentId,
                },
            },
        );

        return response.data;
    } catch (error) {
        console.error(error);
    }
};
