import { StateSchema } from 'app/providers/StoreProvider';
import { UserPermissions } from '../consts/userConsts';

// Order
export const getUserCanEditPendingApprovalOrder = (state: StateSchema) =>
    state.profile.data?.permissionsList.includes(
        UserPermissions.EDIT_PENDING_APPROVAL_ORDER,
    );
export const getUserCanEditInReviewOrder = (state: StateSchema) =>
    state.profile.data?.permissionsList.includes(
        UserPermissions.EDIT_IN_REWIEW_ORDER,
    );

export const getUserCanRejectOrder = (state: StateSchema) =>
    state.profile.data?.permissionsList.includes(UserPermissions.REJECT_ORDER);

export const getUserCanApproveOrder = (state: StateSchema) =>
    state.profile.data?.permissionsList.includes(UserPermissions.APPROVE_ORDER);

export const getUserCanMoveOrderToPendingApproval = (state: StateSchema) =>
    state.profile.data?.permissionsList.includes(
        UserPermissions.MOVE_ORDER_TO_PENDING_APPROVAL,
    );

export const getUserCanAddOrder = (state: StateSchema) =>
    state.profile.data?.permissionsList.includes(UserPermissions.ADD_ORDER);

// Order item
export const getUserCanAddOrderItem = (state: StateSchema) =>
    state.profile.data?.permissionsList.includes(
        UserPermissions.ADD_ORDER_ITEM,
    );

export const getUserCanDeleteOrderItem = (state: StateSchema) =>
    state.profile.data?.permissionsList.includes(
        UserPermissions.DELETE_ORDER_ITEM,
    );

export const getUserCanChangeOrderItem = (state: StateSchema) =>
    state.profile.data?.permissionsList.includes(
        UserPermissions.CHANGE_ORDER_ITEM,
    );

export const getUserCanSeePrices = (state: StateSchema) =>
    state.profile.data?.permissionsList.includes(
        UserPermissions.CAN_SEE_PRICES,
    );

export const getUserCanEditPropertyCatalog = (state: StateSchema) =>
    state.profile.data?.permissionsList.includes(
        UserPermissions.EDIT_PROPERTY_CATALOG,
    );

export const getUserCanDownloadLabels = (state: StateSchema) =>
    state.profile.data?.permissionsList.includes(
        UserPermissions.API_DOWNLOAD_LABELS,
    );

export const getUserCanDownloadSelectedLabels = (state: StateSchema) =>
    state.profile.data?.permissionsList.includes(
        UserPermissions.API_DOWNLOAD_LABELS,
    );
