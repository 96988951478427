import { useTranslation } from 'react-i18next';
import { memo } from 'react';
import { OrderStatuses } from 'entities/Order/model/consts/orderStatuses';
import { ChangeOrderStatusButton } from './ChangeOrderStatusButton';

interface PlaceOrderButtonProps {
    orderId: string;
    isNoItemsInOrder: boolean;
    isRequiredPoNumberForOrder: boolean;
}

export const ApproveOrderButton = memo(
    ({
        orderId,
        isNoItemsInOrder,
        isRequiredPoNumberForOrder,
    }: PlaceOrderButtonProps) => {
        const { t } = useTranslation();

        return (
            <ChangeOrderStatusButton
                newStatus={OrderStatuses.APPROVED}
                orderId={orderId}
                messageOnSuccess={t('Order approved')}
                variant="contained"
                disabled={isNoItemsInOrder}
                isRequiredPoNumberForOrder={isRequiredPoNumberForOrder}
            >
                {t('Confirm Order')}
            </ChangeOrderStatusButton>
        );
    },
);
