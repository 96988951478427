import { useRef } from 'react';
import { Box, Typography, TableCell, TableRow } from '@mui/material';
import { CatalogItem } from 'entities/CatalogItem/model/types/CatalogItemSchema';
import { CategoryLabel } from 'entities/Category';
import { CatalogItemCheckbox } from 'features/CatalogItemCheckbox';
import { CatalogItemImage } from 'shared/ui/CatalogItemImage';
import { useGetItemDataUpdate } from 'features/CatalogItemCheckbox/lib/useGetItemDataUpdate';

interface OrderInventoryTableRowProps {
    item: CatalogItem;
    canSeePrices: boolean;
    onExpandRow: () => void;
}

export const OrderInventoryTableRow = ({
    item,
    canSeePrices,
    onExpandRow,
}: OrderInventoryTableRowProps) => {
    const { isActive, idToEdit, itemIsInOrder, qty } = useGetItemDataUpdate({
        item,
    });

    const windowWidth = useRef(window.innerWidth);
    const isWidthLessThan1400 = windowWidth.current <= 1400;

    return (
        <TableRow
            onClick={() => onExpandRow()}
            sx={{
                cursor: 'pointer',
                position: 'relative',
                boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.10)',
                borderRadius: '4px',
                '& td': {
                    overflow: 'hidden',
                    bgcolor: 'background.paper',
                    '&:first-of-type".': {
                        borderRadius: '4px 0 0 4px',
                    },
                    '&:last-of-type': {
                        borderRadius: '0 4px 4px 0',
                    },
                },
            }}
        >
            <TableCell padding="checkbox">
                <Box display="flex" p="8px 0 8px 16px" alignItems="center">
                    <Box width="70px" height="70px" borderRadius="4px">
                        <CatalogItemImage src={item.thumbnailUrl} />
                    </Box>
                </Box>
            </TableCell>
            <TableCell
                sx={{
                    verticalAlign: 'top',
                    wordWrap: 'break-word',
                    boxSizing: 'border-box',
                }}
            >
                <Box>
                    <Typography
                        typography={
                            isWidthLessThan1400
                                ? 'openSans.body2'
                                : 'openSans.subtitle1Medium'
                        }
                    >
                        {item.title}
                    </Typography>
                    <CategoryLabel categoryId={item.categoryId} />
                </Box>
            </TableCell>
            <TableCell
                align="center"
                sx={{
                    verticalAlign: 'top',
                    width: '112px',
                    maxWidth: '112px',
                    boxSizing: 'content-box',
                }}
            >
                <Typography typography="openSans.body" align="center">
                    {item.sku}
                </Typography>
            </TableCell>
            <TableCell
                align="center"
                sx={{
                    verticalAlign: 'top',
                    width: '60px',
                    maxWidth: '60px',
                    boxSizing: 'content-box',
                }}
            >
                <Typography typography="openSans.body" align="center">
                    {item.qtyPerUom}
                </Typography>
            </TableCell>
            <TableCell
                align="center"
                sx={{
                    verticalAlign: 'top',
                    width: '52px',
                    maxWidth: '52px',
                    boxSizing: 'content-box',
                }}
            >
                <Typography typography="openSans.body" align="center">
                    {item.uom}
                </Typography>
            </TableCell>
            <TableCell
                sx={{
                    verticalAlign: 'top',
                    width: '80px',
                    maxWidth: '80px',
                    boxSizing: 'content-box',
                }}
                align="center"
            >
                <Typography typography="openSans.body">
                    {item.minAllowedInStock || 0}
                </Typography>
            </TableCell>
            {canSeePrices && (
                <TableCell
                    sx={{
                        verticalAlign: 'top',
                        width: '84px',
                        maxWidth: '84px',
                        boxSizing: 'content-box',
                    }}
                    align="right"
                >
                    <Typography typography="openSans.body">
                        ${item.price || 0.0}
                    </Typography>
                </TableCell>
            )}
            <TableCell>
                <Box position="absolute" top="46px" right="16px">
                    <CatalogItemCheckbox
                        minimized
                        showQtyCheckbox
                        isActive={isActive}
                        quickEdit
                        qty={qty}
                        itemId={item.id}
                        itemUid={item.uuid}
                        itemIdToEdit={idToEdit}
                        isInOrder={itemIsInOrder}
                    />
                </Box>
            </TableCell>
        </TableRow>
    );
};
