import { memo } from 'react';
import { Box, BoxProps, Typography } from '@mui/material';
import { PropertyCardRow } from './PropertyCardRow';
import { useTranslation } from 'react-i18next';
import { Property } from 'entities/Property/model/types/PropertySchema';

type PropertyCardInfoProps = {
    propertyItem: Property;
} & BoxProps;

export const PropertyCardInfo = memo(
    ({ mb, propertyItem }: PropertyCardInfoProps) => {
        const { t } = useTranslation();

        return (
            <Box mb={mb}>
                <Typography typography={'poppins.subtitle1'} mb="8px">
                    {t('Property Info')}
                </Typography>
                <PropertyCardRow
                    bordered
                    name={t('Phone')}
                    value={propertyItem.propertyPhoneNumber}
                />
                <PropertyCardRow
                    bordered
                    isEmail
                    name={t('Email')}
                    value={propertyItem.propertyEmail}
                />
            </Box>
        );
    },
);
