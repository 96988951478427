import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Property, PropertySchema } from '../types/PropertySchema';
import { fetchProperties } from '../services/fetchProperties/fetchProperties';

const initialState: PropertySchema = {
    isLoading: false,
    selectedPropertyIdForReports: '',
};

export const PropertySlice = createSlice({
    name: 'Property',
    initialState,
    reducers: {
        selectProperty: (state, action: PayloadAction<string>) => {
            state.selectedData = state.dataList?.find(
                (property) => property.id === action.payload,
            );
            state.selectedId = action.payload;
        },
        setPropertyId: (state, action: PayloadAction<string>) => {
            state.selectedId = action.payload;
        },
        setPropertyIdForReports: (state, action: PayloadAction<string>) => {
            const dataOfProperty = state.dataList?.find(
                (property) => property.id === action.payload,
            );
            state.selectedReportData = dataOfProperty;
            state.selectedPropertyIdForReports = action.payload;
        },
        clearStore: (state) => {
            state.dataList = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProperties.pending, (state) => {
                state.error = null;
                state.isLoading = true;
            })
            .addCase(
                fetchProperties.rejected,
                (state, action: PayloadAction<string>) => {
                    state.isLoading = false;
                    state.error = action.payload;
                },
            )
            .addCase(
                fetchProperties.fulfilled,
                (state, action: PayloadAction<Property[]>) => {
                    state.isLoading = false;
                    state.dataList = action.payload;
                    if (!state.selectedId && !state.selectedData) {
                        state.selectedData = state.dataList?.[0];
                        state.selectedId = state.selectedData?.id;
                    } else if (state.selectedId) {
                        const foundId = state.dataList?.find(
                            (property) => property.id === state.selectedId,
                        );
                        state.selectedData = foundId || state.dataList?.[0];
                    }
                },
            );
    },
});

export const { actions: propertyActions } = PropertySlice;
export const { reducer: propertyReducer } = PropertySlice;
