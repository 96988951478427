import { StateSchema } from 'app/providers/StoreProvider';

export const getMoveToInventoryItemDialog = (state: StateSchema) =>
    state.moveToInventoryItem.showDialog;

export const geyMoveToInventoryItemIsLoading = (state: StateSchema) =>
    state.moveToInventoryItem.isLoading;

export const getMoveToInventoryIsSingle = (state: StateSchema) =>
    state.moveToInventoryItem.isSingle;
