import { createSlice } from '@reduxjs/toolkit';
import { ProductSourcingSchema } from '../types/ProductSourcingSchema';
import { fetchSuppliers } from '../services/fetchSuppliers/fetchSuppliers';
import { applySettingsToProperty } from '../services/applySettingsToProperty/applySettingsToProperty';
import { applySettingsForAllProperties } from '../services/applySettingsForAllProperties/applySettingsForAllProperties';

const initialState: ProductSourcingSchema = {
    listOfSuppliers: null,
    isLoadingSuppliers: false,
    isSaveSettings: false,
    isSaveSettingsToAllProperties: false,
};

export const ProductSourcingSlice = createSlice({
    name: 'ProductSourcing',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSuppliers.pending, (state) => {
                state.isLoadingSuppliers = true;
            })
            .addCase(fetchSuppliers.fulfilled, (state, action) => {
                const suppliers = action.payload.data;
                state.listOfSuppliers = suppliers;
                state.isLoadingSuppliers = false;
            })
            .addCase(fetchSuppliers.rejected, (state) => {
                state.isLoadingSuppliers = false;
            })
            .addCase(applySettingsToProperty.pending, (state) => {
                state.isSaveSettings = true;
            })
            .addCase(applySettingsToProperty.fulfilled, (state) => {
                state.isSaveSettings = false;
            })
            .addCase(applySettingsToProperty.rejected, (state) => {
                state.isSaveSettings = false;
            })
            .addCase(applySettingsForAllProperties.pending, (state) => {
                state.isSaveSettingsToAllProperties = true;
            })
            .addCase(applySettingsForAllProperties.fulfilled, (state) => {
                state.isSaveSettingsToAllProperties = false;
            })
            .addCase(applySettingsForAllProperties.rejected, (state) => {
                state.isSaveSettingsToAllProperties = false;
            });
    },
});

export const { actions: productSourcingActions } = ProductSourcingSlice;
export const { reducer: productSourcingReducer } = ProductSourcingSlice;
