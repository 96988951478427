import OpenSansRegular from 'shared/assets/fonts/OpenSans/OpenSans-Regular.ttf';
import OpenSansSemiBold from 'shared/assets/fonts/OpenSans/OpenSans-SemiBold.ttf';
import OpenSansBold from 'shared/assets/fonts/OpenSans/OpenSans-Bold.ttf';

import PoppinsRegular from 'shared/assets/fonts/Poppins/Poppins-Regular.ttf';
import PoppinsSemiBold from 'shared/assets/fonts/Poppins/Poppins-SemiBold.ttf';
import PoppinsBold from 'shared/assets/fonts/Poppins/Poppins-Bold.ttf';

const fontsStyles = `
    @font-face {}

    @font-face {
        font-family: 'OpenSans';
        font-weight: 400;
        src: url(${OpenSansRegular}) format('truetype');
    }

    @font-face {
        font-family: 'OpenSans';
        font-weight: 600;
        src: url(${OpenSansSemiBold}) format('truetype');
    }

    @font-face {
        font-family: 'OpenSans';
        font-weight: 700;
        src: url(${OpenSansBold}) format('truetype');
    }

    @font-face {
        font-family: 'Poppins';
        font-weight: 400;
        src: url(${PoppinsRegular}) format('truetype');
    }
    
    @font-face {
        font-family: 'Poppins';
        font-weight: 600;
        src: url(${PoppinsSemiBold}) format('truetype');
    }

    @font-face {
        font-family: 'Poppins';
        font-weight: 700;
        src: url(${PoppinsBold}) format('truetype');
    }
    /* Webkit browsers (Chrome, Safari, and newer versions of Opera) */

    ::-webkit-scrollbar {
        width: 4px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #3F6EB7;
        border-radius: 4px;
    }

    /* Firefox and other browsers */
    .scrollbar {
        scrollbar-width: thin;
        scrollbar-color: #3F6EB7 transparent;
    }

    /* Handle */
    .scrollbar::-webkit-scrollbar-thumb {
        background-color: #3F6EB7;
        border-radius: 4px;
    }

    /* Track */
    .scrollbar::-webkit-scrollbar-track {
        background-color: transparent;
    }

    /* Corner */
    .scrollbar::-webkit-scrollbar-corner {
        background-color: transparent;
    }
`;

export const Baseline = {
    styleOverrides: { root: fontsStyles },
};
