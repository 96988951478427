import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { Report } from '../../types/ReportsShema';

export const fetchReports = createAsyncThunk<
    Report[],
    { propertyId: string; dateStart: string; dateEnd: string },
    ThunkConfig<string>
>(
    'reports/fetchReports',
    async ({ propertyId, dateStart, dateEnd }, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi;
        try {
            const response = await extra.api.get<Report[]>(
                `/v1/reports/categories-stats?property_id=${propertyId}&date_start=${dateStart}&date_end=${dateEnd}`,
            );

            return response.data;
        } catch (e) {
            return rejectWithValue('error');
        }
    },
);
