import { Box } from '@mui/material';
import { FunctionComponent, ReactNode, SVGProps } from 'react';

interface EmptyPlaceholderProps {
    image: FunctionComponent<SVGProps<SVGSVGElement>>;
    children: ReactNode;
}

export const EmptyPlaceholder = ({
    children,
    image,
}: EmptyPlaceholderProps) => {
    return (
        <Box
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
        >
            <img width="42px" src={String(image)} alt="Open box search" />
            <Box m="24px 0">{children}</Box>
        </Box>
    );
};
