import { CircularProgress, CircularProgressProps } from '@mui/material';

interface LoaderProps {
    size?: number;
    color?: CircularProgressProps['color'];
}

export const Loader = ({ size, color }: LoaderProps) => {
    const iconSize = `${size || '24'}px`;

    return <CircularProgress color={color || 'info'} size={iconSize} />;
};
