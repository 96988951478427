import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ReportsSchema } from '../types/ReportsShema';
import { fetchReports } from '../services/fetchReports/fetchReports';

const initialState: ReportsSchema = {
    reportsIsLoading: false,
    reportsAllData: [[]],
    reportsShowTableData: [],
    reportsShowChartData: [],
    amountOfCategories: 0,
    error: '',
    currentPage: 0,
    rowsPerPage: 20,
};

export const ReportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        setCurrentPage(state, action: PayloadAction<number>) {
            const { reportsAllData } = state;
            state.currentPage = action.payload;
            state.reportsShowTableData = reportsAllData[action.payload];
            state.reportsShowChartData = reportsAllData[action.payload].map(
                (report) => ({
                    ...report,
                    totalAmount: +report.totalAmount,
                }),
            );
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchReports.pending, (state) => {
                state.error = null;
                state.reportsIsLoading = true;
            })
            .addCase(fetchReports.fulfilled, (state, action) => {
                const { rowsPerPage, currentPage } = state;
                const payloadArray = action?.payload || [];
                const paginationArraysOfReports = [];

                for (let i = 0; i < payloadArray.length; i += rowsPerPage) {
                    const rowSlice = payloadArray.slice(i, i + rowsPerPage);
                    paginationArraysOfReports.push(rowSlice);
                }

                state.amountOfCategories = payloadArray.length;
                state.reportsAllData = paginationArraysOfReports;
                const showReportsData = paginationArraysOfReports[currentPage];
                state.reportsShowTableData = showReportsData;

                state.reportsShowChartData = showReportsData.map((report) => {
                    const suppliers = Object.entries(report.bySuppliers)
                        .filter(([_, supplier]) => supplier.total !== '0')
                        .map(([_, supplier]) => ({
                            name: supplier.name,
                            total: supplier.total,
                        }))
                        .sort(
                            (a, b) => parseFloat(b.total) - parseFloat(a.total),
                        );

                    const totalAmountForOther =
                        suppliers.length > 5
                            ? suppliers
                                  .slice(5)
                                  .reduce(
                                      (total, current) =>
                                          total + +current.total,
                                      0,
                                  )
                            : '';

                    return {
                        ...report,
                        totalAmount: +report.totalAmount,
                        bySuppliers:
                            suppliers.length > 5
                                ? [
                                      ...suppliers.slice(0, 5),
                                      {
                                          name: 'Others',
                                          total: totalAmountForOther.toString(),
                                      },
                                  ]
                                : suppliers,
                    };
                });

                state.reportsIsLoading = false;
            })
            .addCase(fetchReports.rejected, (state, action) => {
                state.reportsAllData = [];
                state.reportsIsLoading = false;
                state.error = action.payload;
            });
    },
});

export const { actions: reportsActions } = ReportsSlice;
export const { reducer: reportsReducer } = ReportsSlice;
