import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Vendor, VendorsSchema } from '../types/VendorsSchema';
import { fetchVendorsList } from '../services/fetchVendorsList';

const initialState: VendorsSchema = {
    dataIsLoading: false,
    vendorsList: [],
};

export const VendorsSlice = createSlice({
    name: 'Vendors',
    initialState,
    reducers: {
        setVendorsList: (state, action: PayloadAction<Vendor[]>) => {
            state.vendorsList = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchVendorsList.pending, (state) => {
                state.dataIsLoading = true;
            })
            .addCase(fetchVendorsList.fulfilled, (state, action) => {
                const listOfVendors = action.payload.data;
                state.vendorsList = listOfVendors;
                state.dataIsLoading = false;
            })
            .addCase(fetchVendorsList.rejected, (state, action) => {
                state.dataIsLoading = false;
            });
    },
});

export const { actions: vendorsActions } = VendorsSlice;
export const { reducer: vendorsReducer } = VendorsSlice;
