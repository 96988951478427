import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Dialog, Typography } from '@mui/material';
import { DialogBoxContent } from '../DialogBoxContent/DialogBoxContent';
import { Loader } from '../Loader/Loader';

interface RemoveModalProps {
    isShow: boolean;
    isLoading: boolean;
    title: string;
    text: string;
    onClose: () => void;
    onSubmit: () => void;
}

export default memo(
    ({
        isShow,
        title,
        text,
        onClose,
        onSubmit,
        isLoading,
    }: RemoveModalProps) => {
        const { t } = useTranslation();

        const cancel = useCallback(() => {
            if (!isLoading) {
                onClose();
            }
        }, [isLoading, onClose]);

        const submit = useCallback(() => {
            if (!isLoading) {
                onSubmit();
            }
        }, [isLoading, onSubmit]);

        return (
            isShow && (
                <Dialog open>
                    <DialogBoxContent
                        onClose={cancel}
                        buttons={
                            <Box
                                display="flex"
                                justifyContent="flex-end"
                                width="100%"
                                gap="10px"
                            >
                                <Button onClick={cancel} color="primary">
                                    {t('No')}
                                </Button>
                                <Button
                                    variant="contained"
                                    startIcon={
                                        isLoading && <Loader color="inherit" />
                                    }
                                    disabled={isLoading}
                                    onClick={submit}
                                >
                                    {t('Yes')}
                                </Button>
                            </Box>
                        }
                    >
                        <Box maxWidth="600px">
                            <Typography
                                mb="20px"
                                typography="poppins.subtitle2"
                            >
                                {title}
                            </Typography>
                            <Typography mb="8px" typography="openSans.body2">
                                {text}.
                            </Typography>
                            <Typography mb="10px" typography="openSans.body2">
                                {t('Are you sure')}?
                            </Typography>
                        </Box>
                    </DialogBoxContent>
                </Dialog>
            )
        );
    },
);
