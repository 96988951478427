import { Dialog } from '@mui/material';
import {
    getAddCatalogAddedItemsCount,
    getAddCatalogItemDuplicates,
    getShowDialog,
} from 'features/AddCatalogItem/model/selectors/addCatalogItemSelectors';
import { addCatalogItemActions } from 'features/AddCatalogItem/model/slices/AddCatalogItemSlice';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isArray } from 'shared/lib/lodash/lodash';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { AddCatalogItemDialogForm } from './AddCatalogItemDialogForm';
import { AddCatalogItemDialogError } from './AddCatalogItemDialogError';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export const AddCatalogItemDialog = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const isOpened = useSelector(getShowDialog);
    const duplicates = useSelector(getAddCatalogItemDuplicates);
    const addedItemsCount = useSelector(getAddCatalogAddedItemsCount);

    const hasDuplicates = isArray(duplicates) && duplicates.length > 0;

    const onCloseDialog = useCallback(() => {
        dispatch(addCatalogItemActions.setShowDialog(false));
        if (addedItemsCount) {
            dispatch(addCatalogItemActions.clearAddedItemsCount());
            const titleValue = `${addedItemsCount} ${
                addedItemsCount === 1
                    ? t('Item successfully added')
                    : t('Items successfully added')
            }`;
            toast(titleValue);
        }
    }, [addedItemsCount, dispatch, t]);

    const onClose = useCallback(() => {
        dispatch(addCatalogItemActions.setShowDialog(false));
        onCloseDialog();
    }, [dispatch, onCloseDialog]);

    const onCloseWithDuplicates = useCallback(() => {
        dispatch(addCatalogItemActions.clearDuplicates());
        onCloseDialog();
    }, [dispatch, onCloseDialog]);

    return (
        isOpened && (
            <Dialog
                data-testid="AddCatalogItemDialog"
                open
                onClose={hasDuplicates ? onCloseWithDuplicates : onClose}
            >
                {!hasDuplicates ? (
                    <AddCatalogItemDialogForm onClose={onClose} />
                ) : (
                    <AddCatalogItemDialogError
                        duplicates={duplicates}
                        onClose={onCloseWithDuplicates}
                    />
                )}
            </Dialog>
        )
    );
};
