import { StateSchema } from 'app/providers/StoreProvider';
import {
    getCatalogItems,
    getSelectedCatalogItems,
} from 'entities/CatalogItem/model/selectors/catalogItemSelectors';

export const getCurrentOrderSummary = (state: StateSchema) => {
    const catalogItems = getCatalogItems(state);
    const selectedItems = getSelectedCatalogItems(state);

    let result = 0;

    catalogItems?.forEach((item) => {
        if ('price' in item) {
            const pricePerItem = item?.selectedProposal?.price || item?.price;
            result += Number(pricePerItem) * selectedItems?.[item.id]?.qty;
        }
    });

    return result.toFixed(2);
};
