import { useRef, useEffect } from 'react';

interface LazyLoadOnScrollProps<T> {
    items: T[];
    renderItem: (item: T, index?: number) => JSX.Element;
    onLoad: () => Promise<void>;
}

const LazyLoadOnScroll = <T,>({
    items,
    renderItem,
    onLoad,
}: LazyLoadOnScrollProps<T>) => {
    const observer = useRef<IntersectionObserver | null>(null);
    const lastItemRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const currentLastItemRef = lastItemRef.current;
        observer.current = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    onLoad();
                }
            },
            { rootMargin: '0px 0px 200px 0px' },
        );
        if (currentLastItemRef) {
            observer.current.observe(currentLastItemRef);
        }
        return () => {
            if (currentLastItemRef) {
                observer.current.unobserve(currentLastItemRef);
            }
        };
    }, [items, onLoad]);

    return (
        <>
            {items.map((item, index) => (
                <div key={index}>{renderItem(item, index)}</div>
            ))}
            <div ref={lastItemRef} />
        </>
    );
};

export default LazyLoadOnScroll;
