import { StateSchema } from 'app/providers/StoreProvider';

export const getProposalFilter = (state: StateSchema) =>
    state.orderProposalFilter.filter;

export const getProposalFilterIsActive = (state: StateSchema) =>
    state.orderProposalFilter.filter !== 'All';

export const getProposalFilteredItems = (state: StateSchema) =>
    state.orderProposalFilter.filteredItems;

export const getProposalFilterSellersData = (state: StateSchema) =>
    state.orderProposalFilter.filterSellersData;
