import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { getUserIsAuthed, getUserRole } from 'entities/User';
import {
    getRouteAccessDenied,
    getRouteForbidden,
    getRouteMain,
} from 'shared/const/router';
import { UserRole } from 'entities/User/model/consts/userConsts';
import {
    getUserHasPMCId,
    getUserRoleIsAcceptable,
} from 'entities/User/model/selectors/userSelectors';

interface RequireAuthProps {
    children: JSX.Element;
    roles?: UserRole[];
}

export function RequireAuth({ children, roles }: RequireAuthProps) {
    const auth = useSelector(getUserIsAuthed);
    const userRole = useSelector(getUserRole);
    const haveAccessToApp = useSelector(getUserRoleIsAcceptable);
    const userHasPMC = useSelector(getUserHasPMCId);

    const hasRequiredRole = useCallback(
        (userRole: UserRole) => roles.includes(userRole),
        [roles],
    );

    if (!auth) {
        return <Navigate to={getRouteMain()} replace />;
    }

    if ((auth && !haveAccessToApp) || !userHasPMC) {
        return <Navigate to={getRouteAccessDenied()} replace />;
    }

    if (!!roles && !hasRequiredRole(userRole)) {
        return <Navigate to={getRouteForbidden()} replace />;
    }

    return children;
}
