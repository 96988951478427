import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EditMinAllowedInStockSchema } from '../types/EditMinAllowedInStockSchema';
import { editMinAllowedService } from '../services/editMinAllowedService';

const initialState: EditMinAllowedInStockSchema = {
    value: 0,
    valueIsChanged: false,
    isLoading: false,
};

export const EditMinAllowedInStockSlice = createSlice({
    name: 'EditMinAllowedInStock',
    initialState,
    reducers: {
        setValue: (state, action: PayloadAction<number>) => {
            state.value = action.payload;
        },
        setValueIsChanged: (state, action: PayloadAction<boolean>) => {
            state.valueIsChanged = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(editMinAllowedService.pending, (state) => {
                state.error = undefined;
                state.isLoading = true;
            })
            .addCase(editMinAllowedService.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(editMinAllowedService.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

export const { actions: editMinAllowedInStockActions } =
    EditMinAllowedInStockSlice;
export const { reducer: editMinAllowedInStockReducer } =
    EditMinAllowedInStockSlice;
