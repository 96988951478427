import { Box, Skeleton } from '@mui/material';

export const OrderInventoryTableRowSkeleton = () => {
    return (
        <Box
            display="flex"
            gap="12px"
            p="8px"
            mr="4px"
            bgcolor="background.paper"
            borderRadius="4px"
            boxShadow="0px 2px 6px 0px rgba(0, 0, 0, 0.10)"
        >
            <Box display="flex" gap="8px" width="100%">
                <Box>
                    <Skeleton
                        width="50px"
                        variant="rectangular"
                        height="50px"
                    />
                </Box>
                <Box flex="1 1">
                    <Box width="100%">
                        <Box display="flex" justifyContent="space-between">
                            <Skeleton
                                variant="rectangular"
                                width="190px"
                                height="30px"
                                sx={{ mb: '8px' }}
                            />
                            <Skeleton
                                variant="rectangular"
                                width="190px"
                                height="30px"
                                sx={{ mb: '8px' }}
                            />
                        </Box>
                        <Skeleton
                            variant="rectangular"
                            width="100%"
                            sx={{ flex: '1 1' }}
                            height="28px"
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
