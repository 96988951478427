import { TextField } from '@mui/material';
import { useField } from 'formik';
import { FocusEventHandler } from 'react';

interface FormikInputProps {
    name: string;
    label: string;
    disabled?: boolean;
    hasError?: boolean;
    readOnly?: boolean;
    loading?: boolean;
    minimized?: boolean;
    onBlurAction?: () => void;
}

const minimizedStyle = {
    '& .MuiInputBase-root': {
        height: '40px',
        position: 'relative',
    },
    '& label': {
        top: '-8px',
    },
    '& .MuiInputBase-root input': {
        width: 'calc(100% - 48px)',
    },
};

const FormikInput = ({
    name,
    label,
    disabled,
    readOnly,
    hasError,
    loading,
    minimized,
    onBlurAction,
}: FormikInputProps) => {
    const [{ value, onBlur, onChange }, { touched, error }] = useField(name);

    const blur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (
        e,
    ) => {
        if (onBlurAction) {
            onBlurAction();
        }
        onBlur(e);
    };

    return (
        <TextField
            data-testid={`Formik-input-${name}`}
            sx={minimized ? minimizedStyle : {}}
            id={name}
            disabled={loading || disabled}
            label={label}
            variant="outlined"
            fullWidth
            value={value || ''}
            onChange={onChange}
            onBlur={blur}
            error={(touched && Boolean(error)) || hasError}
            helperText={touched ? error : ''}
            InputProps={{
                readOnly,
            }}
        />
    );
};

export default FormikInput;
