import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { Order } from '../../types/OrderSchema';

export interface FetchOrdersParams {
    propertyId?: string;
    status?: string;
    pagination: { pageSize: number; currentPage: number };
    sorting: {
        column: string;
        direction: 'asc' | 'desc';
    };
}

export interface FetchOrdersResponse {
    count: number;
    next: number;
    previous: number;
    results: Order[];
}

const buildColumnParam = (column: string, isDesc: boolean) => {
    return column
        .split(',')
        .map((param) => (isDesc ? `-${param}` : param))
        .join();
};

export const fetchOrders = createAsyncThunk<
    FetchOrdersResponse,
    FetchOrdersParams,
    ThunkConfig<string>
>(
    'order/fetchOrders',
    async ({ propertyId, status, pagination, sorting }, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi;

        const { pageSize, currentPage } = pagination;
        const { column, direction } = sorting;

        let statusForRequest;
        if (status === 'PROCESSING') {
            statusForRequest =
                'IN_PROGRESS,WAITING_CONFIRMATION,CONFIRMED,SUBMITTED,READY_TO_SHIP,ATTENTION_REQUIRED';
        } else if (status === 'INVOICED') {
            statusForRequest = 'INVOICED,COMPLETE';
        } else {
            statusForRequest = status;
        }

        let ordering = buildColumnParam(column, direction === 'desc');

        if (column !== 'order_number') {
            ordering = ordering + ',-order_number';
        }

        try {
            const response = await extra.api.get<FetchOrdersResponse>(
                `/v1/orders/`,
                {
                    params: {
                        propertyId,
                        status: statusForRequest,
                        pageSize,
                        page: currentPage + 1,
                        ordering,
                    },
                },
            );

            return response.data;
        } catch (e) {
            return rejectWithValue('error');
        }
    },
);
