import { Box, Theme, Typography, alpha } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface CatalogItemPriceProps {
    price: number;
    total?: string;
}

export const CatalogItemPrice = ({ price, total }: CatalogItemPriceProps) => {
    const { t } = useTranslation();

    const styles = useMemo(
        () => ({
            fontSize: '12px',
            fontStyle: 'italic',
            letterSpacing: '0.15px',
            color: (theme: Theme) => alpha(theme.palette.common.black, 0.5),
        }),
        [],
    );

    return (
        <Box display="flex" justifyContent="space-between" p="8px 0 4px 0">
            <Box
                data-testid="Item-price"
                display="flex"
                gap="2px"
                alignItems="flex-end"
            >
                <Typography {...styles} fontSize="16px">
                    ${price}/{t('UOM')}
                </Typography>
            </Box>
            {total ? (
                <Box
                    data-testid="Item-total-price"
                    display="flex"
                    gap="4px"
                    alignItems="flex-end"
                >
                    <Typography {...styles} lineHeight="16px">
                        {t('Total')}:
                    </Typography>
                    <Typography
                        typography="openSans.subtitle1Medium"
                        fontWeight="bold"
                        color="black"
                    >
                        ${total}
                    </Typography>
                </Box>
            ) : null}
        </Box>
    );
};
