import { Order } from 'entities/Order/model/types/OrderSchema';
import { convertDate } from 'shared/lib/convertDate/convertDate';
import { capitalize } from 'shared/lib/lodash/lodash';

export const getDataFromOrder = (order: Order) => {
    const id = order.id;
    const orderNumber = order.orderNumber || order.id;

    const itemsCount = order.itemsCount || 0;

    const { day: createdAtDay, time: createdAtTime } = convertDate(
        order.createdAt,
    );

    const { day: updatedAtDay, time: updatedAtTime } = convertDate(
        order.updatedAt,
    );

    const creatorName = `${order.creator?.firstName || ''} ${
        order.creator?.lastName || ''
    }`;

    const totalPrice = order?.totalPrice || 0;

    const lastUpdatedBy = `${order.lastUpdatedBy?.firstName || ''} ${
        order.lastUpdatedBy?.lastName || ''
    }`;

    const orderType = capitalize(
        order.orderType === 'RENOVATION' ? 'Renovations' : 'Operations',
    );

    return {
        id,
        orderNumber,
        creatorName,
        lastUpdatedBy,
        itemsCount,
        totalPrice,
        createdAtDay,
        createdAtTime,
        updatedAtDay,
        updatedAtTime,
        orderType,
    };
};
