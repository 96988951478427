import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';

export interface MakeAllNotifReadResponse {
    notificationsRead: number;
    status: string;
}

export const makeNotificationsAsReadService = createAsyncThunk<
    MakeAllNotifReadResponse,
    string[],
    ThunkConfig<string>
>(
    'notifications/makeAllNotificationsAsReadService',
    async (notificationIds, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi;

        try {
            const response = await extra.api.post<MakeAllNotifReadResponse>(
                `/v1/users/notifications`,
                { notificationIds },
            );

            return response.data;
        } catch (e) {
            return rejectWithValue('error');
        }
    },
);
