import { StateSchema } from 'app/providers/StoreProvider';

export const getNotifications = (state: StateSchema) =>
    state.notification.dataList || [];

export const getHasUnreadNotifications = (state: StateSchema) =>
    Boolean(state.notification?.unreadCounter);

export const getNotificationsUnreadIds = (state: StateSchema) =>
    state.notification?.unreadIds || [];

export const getUnreadNotificationsCounter = (state: StateSchema) => {
    return state.notification?.unreadCounter;
};

export const getNotificationsAreLoading = (state: StateSchema) =>
    state.notification.initialIsLoading;

export const getNotificationsAreLoadingMore = (state: StateSchema) =>
    state.notification.moreIsLoading;

export const getNotificationsHasMore = (state: StateSchema) =>
    state.notification?.pagination?.hasMore;

export const getNotificationsCurrentPage = (state: StateSchema) =>
    state?.notification?.pagination?.currentPage;

export const getNotificationsPageSize = (state: StateSchema) =>
    state.notification?.pagination?.pageSize;
