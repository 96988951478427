import { StateSchema } from 'app/providers/StoreProvider';

export const getPropertiesList = (state: StateSchema) =>
    state.property.dataList;
export const getPropertiesListLoading = (state: StateSchema) =>
    state.property.isLoading;
export const getSelectedProperty = (state: StateSchema) =>
    state.property.selectedData;
export const getSelectedPropertyId = (state: StateSchema) =>
    state.property.selectedData?.id;
export const getSelectedPropertyCatalogId = (state: StateSchema) =>
    state.property.selectedData?.catalogId;
export const getSelectedPropertyIdForReports = (state: StateSchema) =>
    state.property.selectedPropertyIdForReports;
export const getSelectedPropertyForReports = (state: StateSchema) =>
    state.property.selectedReportData;
