import { StateSchema } from 'app/providers/StoreProvider';
import { isArray } from 'shared/lib/lodash/lodash';

export const getGlobalCategoriesList = (state: StateSchema) =>
    state.category?.globalCategories;

export const getLocalCategoriesList = (state: StateSchema) =>
    state.category?.localCategories;

export const getHasPropertyCategories = (state: StateSchema) => {
    const propertyCategories = state.category?.localCategories;

    return isArray(propertyCategories) && propertyCategories?.length > 0;
};

export const getLocalCategoriesListIsLoading = (state: StateSchema) =>
    state.category.localListIsLoading;

export const getGlobalCategoriesListIsLoading = (state: StateSchema) =>
    state.category.globalListIsLoading;

export const getSelectCategoryTitle = (state: StateSchema) =>
    state.category?.selectedData?.title;

export const getSelectCategoryId = (state: StateSchema) =>
    state.category?.selectedData?.id;

export const getSelectSubCategory = (state: StateSchema) => state.category;

export const getSelectSubCategoryName = (state: StateSchema) =>
    state.category?.selectedSubCategory?.catName;

export const getSelectSubCategoryId = (state: StateSchema) =>
    state.category?.selectedSubCategory?.uid;

export const getSelectedCategoryIdToFetch = (state: StateSchema) =>
    state.category?.selectedSubCategory?.uid ||
    state.category?.selectedData?.id;

export const getManageCategoryError = (state: StateSchema) =>
    state.category?.error;
