import { StateSchema } from 'app/providers/StoreProvider';

export const getEditMinAllowedInStockValue = (state: StateSchema) =>
    state.editMinAllowedInStock.value;

export const getEditMinAllowedInStockIsLoading = (state: StateSchema) =>
    state.editMinAllowedInStock.isLoading;

export const getEditMinAllowedInStockValueIsChanged = (state: StateSchema) =>
    state.editMinAllowedInStock.valueIsChanged;
